import React from 'react'
import { useTranslation } from 'react-i18next';

// React parallax
import { Parallax, Background } from 'react-parallax';

// React Slideshow Image
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// assets
import cans from 'assets/img/aluminumCans.jpg';
import plasticBottles from 'assets/img/plastic-bottles.jpg';
import girlRecycling from 'assets/img/girlRecycling.jpg';
import recyclingDropoff from 'assets/img/Recycling-Drop-off-full.jpg';

const slides = [
  { src: cans, alt: 'Aluminum Cans' },
  { src: plasticBottles, alt: 'Plastic Bottles' },
  { src: girlRecycling, alt: 'Girl Recycling' },
  { src: recyclingDropoff, alt: 'Recycling Drop Off' },
];

// Properties for React Slideshow Images
const properties = {
  arrows: false,
  autoplay: true,
  infinite: true,
  pauseOnHover: false,
  defaultIndex: 0,
  duration: 2000,
  transitionDuration: 1000,
};

const useStyles = makeStyles((theme) => ({
  container_parallax: {
    width: '100vw',
    height: '400px',
    [theme.breakpoints.down('xs')]: {
      height: '200px',
    },
  },
  parallaxTitle: {
    fontSize: '4rem',
    fontWeight: '500',
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    top: '150px',
    left: '15%',
    textShadow: 'rgba(0, 0, 0, 0.4) 3px 3px 7.2px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
      top: '75px',
      left: '35px',
    },
  },
  carouselContainer: {
    position: 'relative',
    width: '100vw',
    height: '1000px',
    [theme.breakpoints.down('xs')]: {
      height: '600px',
    },
  },
  carouselImage: {
    width: '100%',
    height: '1000px',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: '600px',
    },
  },
}));

const ParallaxSection = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <Parallax blur={0} strength={500} className={classes.container_parallax}>
        <Background>
          <div className={classes.carouselContainer}>
            <Fade {...properties}>
              {slides.map((slide, index) => (
                <div key={index}>
                  <img
                    src={slide.src}
                    alt={slide.alt}
                    className={classes.carouselImage}
                  />
                </div>
              ))}
            </Fade>
          </div>
        </Background>
        <Typography className={classes.parallaxTitle}>
          {t('howItWorks.recyclingReimagined')}
        </Typography>
      </Parallax>
    </React.Fragment>
  );
};

export default ParallaxSection
