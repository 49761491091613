import React, { useState, useEffect } from "react";
import { fade, makeStyles } from '@material-ui/core/styles';
import axios from "axios";

// @material-ui/core components
import { Typography, Grid, Container, Toolbar } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

import { SERVER_URL } from '../../config';

// components
import Page from 'components/Page/Page';
import BlogCard from 'components/BlogCard/BlogCard';

// assets
import blogHeader from 'assets/img/blog/blogHeader.png';

// Post Object
// TO DO: create db for posts and get route
import posts from 'lib/blogPost';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    marginTop: '5rem',
    marginBottom: '5rem',
  },
  blogHeaderImage: {
    width: '100%',
    height: 'auto',
  },
  title: {
    fontSize: '5.6rem',
    fontWeight: '200',
    color: theme.palette.primary.main,
  },
  bold: {
    fontWeight: '500',
  },
  searchContainer: {
    margin: '0rem 0',
  },
  search: {
    position: 'relative',
    border: '1px solid',
    borderRadius: '50px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  blogCardContainer: {
    margin: '3rem 0 5rem',
  },
  text: {
    fontSize: '1rem',
    fontWeight: '400',
    marginBottom: '1rem',
  },
}));

const Blogs = (props) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const [postArr, setPostArr] = React.useState([]);
  const [staticPosts, setStatic] = React.useState([]); //Never changes value
  useEffect(() => {
    const config = {headers:{'Content-Type':'application/json'}}
    const fetchPostInfo = async () =>{
        const response = await axios.get(`${SERVER_URL}/blog/posts`, config);
        response.data.reverse() //to get from new to last
        setPostArr(response.data);
        setStatic(response.data);
    }
    fetchPostInfo();
  }, []);

  const handleChange = (event) => { //takes care of the search
    const { value } = event.target;
    const filtered = staticPosts.filter((post) =>
      post.title.toLowerCase().includes(value.toLowerCase())
    );
    setSearch(value);
    setPostArr([...filtered]);
  };

  return (
    <Page>
      <div className={classes.main}>
        <Toolbar />

        <Container maxWidth="lg">
          
          <Grid container xs={12}>
            <Grid item xs={12} sm={9}>
              <Typography color="primary" variant="h3" component="h2">Exciting Recycling Blog</Typography>
            </Grid>
            <Grid item xs={12} sm={3} style={{ alignItems: 'center' }}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  value={search}
                  onChange={handleChange}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
            </Grid>
          </Grid>

          <Container className={classes.blogCardContainer}>
            <Grid container justify="left" alignItems="stretch" spacing={3}>
              {postArr.length > 0 ? (
                postArr.map((post) => (
                  <Grid item key={post.id} xs={12} sm={6} lg={4}>
                    <BlogCard key={post.id} post={post} />
                  </Grid>
                ))
              ) : (
                <Grid item>
                  <Typography variant="body1">No Posts Found</Typography>
                </Grid>
              )}
            </Grid>
          </Container>

        </Container>
      </div>
    </Page>
  );
};

export default Blogs;
