import React from 'react'
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Box } from '@material-ui/core'

// components
import ImageBlock from './ImageBlock'

const useStyles = makeStyles((theme) => ({
  textBox: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '600px'
    }
  },
  textBlock: {
    textAlign: 'justify'
  }
}))

const RecyclingRevSection = (props) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <Grid container spacing={3} justify="center">
        <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            container
            justify="flex-start"
            alignItems="center"
            className={classes.textBox}
          >
            <Grid item>
              <Typography color="primary" variant="h3">
                {t('home.recyclingRevolutionizedTitle')}
              </Typography>
              <Typography className={classes.textBlock} variant="body2">
                {t('home.recyclingRevolutionizedBody')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            container
            justify="center"
            alignItems="center"
          >
            <ImageBlock />
          </Grid>
        </Box>
      </Grid>
    </React.Fragment>
  );
}

export default RecyclingRevSection
