import React from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/core components
import { Typography, Grid, Box } from '@material-ui/core'

// assets
import metrics_graph from 'assets/img/metrics_graph.png'

const useStyles = makeStyles((theme) => ({
  metricsImage: {
    width: '100%',
    height: 'auto'
  },
  list: {
    fontSize: '1.125rem',
    padding: '0 2rem',
    fontWeight: 300
  },
  listItem: {
    lineHeight: 1.2,
    marginBottom: '.75rem'
  },
  subCaption: {
    fontSize: '1.3rem',
    fontWeight: 300
  },
  textBlock: {
    textAlign: 'justify',
    maxWidth: '700px'
  }
}))

const MetricsSection = (props) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item>
          <Typography color="primary" variant="h3">
            {t('howItWorks.recyclingMetrics')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item sx={12}>
          <Typography className={classes.textBlock} variant="caption">
            {t('howItWorks.recyclingMetricsExplanation')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item sm={12} md={6}>
            <img
              src={metrics_graph}
              alt="Metrics Graph"
              className={classes.metricsImage}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Box px={2}>
              <Typography color="primary" variant="h6">
                {t('howItWorks.weShowYou')}
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  {t('howItWorks.metricsInfo.pointOne')}
                </li>
                <li className={classes.listItem}>
                  {t('howItWorks.metricsInfo.pointTwo')}
                </li>
                <li className={classes.listItem}>
                  {t('howItWorks.metricsInfo.pointThree')}
                </li>
                <li className={classes.listItem}>
                  {t('howItWorks.metricsInfo.pointFour')}
                </li>
                <li className={classes.listItem}>
                  {t('howItWorks.metricsInfo.pointFive')}
                </li>
              </ul>
              <Typography className={classes.subCaption}>
                {t('howItWorks.noOtherService')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default MetricsSection
