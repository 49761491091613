import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// Form validation libraries
import { useFormik } from 'formik';
import * as yup from 'yup';

// API
import Customer from 'api/CustomerService';

import { ALERT_FAIL, ALERT_SUCCESS } from 'redux/actions/types';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

// Material UI components
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.tertiary.main,
    fontWeight: 300,
  },
  button: {
    margin: theme.spacing(1),
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));

const WaitlistForm = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { open, handleClose } = props;

  const validationSchema = yup.object({
    email: yup
      .string(t('signup.form.enterEmail'))
      .email(t('signup.form.pleaseEnterValidEmailFormat'))
      .required(t('signup.form.emailRequired')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      zipcode: props.zipcode,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      handleRequest(values);
    },
  });

  const handleRequest = (values) => {
    const investor = {
      email: values.email,
      listname: `waitlist_${values.zipcode}`,
    };
    Customer.joinList(investor)
      .then((res) => {
        dispatch({
          type: ALERT_SUCCESS,
          payload: t('signup.form.thanksForInterest'),
        });
        formik.resetForm();
        handleClose(false, '');
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: ALERT_FAIL,
            payload: t('signup.form.errorSendingEmail'),
          });
        }
      });
  };

  const handleExit = () => {
    formik.resetForm();
    handleClose(false, '');
  };

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      onClose={handleExit}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box mb={1}>
            <Typography className={classes.title}>
              {t('signup.form.notInYourArea')}
            </Typography>
          </Box>
          <Box mb={3}>
            <DialogContentText className={classes.text}>
              {t('signup.form.weAreExpanding')}
            </DialogContentText>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                name="email"
                label={t('signup.form.email')}
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={classes.button} type="submit">
            {t('signup.form.joinWaitlist')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default WaitlistForm;

WaitlistForm.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  zipcode: PropTypes.string,
};
