import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

// @material-ui/core components
import { Container, Hidden, Grid } from '@material-ui/core';

// components
import BlogSection from './Section/BlogSection';
import BusinessCollectionSection from './Section/BusinessCollectionSection';
import DropoffSection from './Section/DropoffSection';
import EcoSupplySection from './Section/EcoSupplySeciton';
import MetricsSection from './Section/MetricsSection';
import Page from 'components/Page/Page';
import ParallaxSection from './Section/ParallaxSection';
import RewardsSection from './Section/RewardsSeciton';
import StepsToRecycleSection from './Section/StepsToRecycleSection';
import TitleSection from './Section/TitleSection';
import GeoLocator from 'components/GeoLocator/GeoLocator';
import SEOTags from 'components/SEOTags/SEOTags';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  fullWidthContainer: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
}));

const HowItWorks = (props) => {
  const classes = useStyles();
  const services = useSelector((state) => state.customer.location.services);

  const getDropOffSection = () => {
    return (
      <Grid container justify="center">
        <DropoffSection />
      </Grid>
    );
  };

  return (
    <>
      <SEOTags
        title="How Reverse Vending Machine Works - Recycletek"
        description="We have reinvented recycling by radically improving the system of collection, identification, and redemption through our reverse vending machine technology."
      />
      <GeoLocator />
      <Page>
        <div className={classes.main}>
          <div className={classes.fullWidthContainer}>
            <TitleSection />
          </div>
          <Container maxWidth="lg" className={classes.container}>
            {services.curbside ? (
              <StepsToRecycleSection />
            ) : (
              getDropOffSection()
            )}
          </Container>
          <div className={classes.fullWidthContainer}>
            <ParallaxSection />
          </div>
          <Container maxWidth="lg" className={classes.fullWidthContainer}>
            {services.curbside ? (
              getDropOffSection()
            ) : (
              <StepsToRecycleSection />
            )}
          </Container>
          <Container maxWidth="lg" className={classes.container}>
            <BusinessCollectionSection />
          </Container>
          <Container maxWidth="lg" className={classes.container}>
            <EcoSupplySection />
          </Container>
          <Container
            maxWidth="lg"
            className={`${classes.container} ${classes.metricsSectionContainer}`}
          >
            <MetricsSection />
          </Container>
          <Container maxWidth="lg" className={classes.container}>
            <RewardsSection />
          </Container>
        </div>
      </Page>
    </>
  );
};

export default HowItWorks;
