import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { resetCustomerPassword } from 'redux/actions/customer';
import { login } from 'redux/actions/auth';
import { ALERT_SUCCESS, ALERT_FAIL } from 'redux/actions/types';

// Form validation libraries
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Button,
  Box,
  Paper,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// components
import PortalTextField from 'components/TextField/PortalTextField';
import { SettingsInputAntennaTwoTone } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    backgroundColor: 'transparent',
    maxWidth: 550,
    boxShadow: 'none',
  },
  title: {
    fontWeight: 300,
    marginBottom: '.5rem',
  },
  subtitle: {
    color: theme.palette.tertiary.main,
  },
  input: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.tertiary.main,
      backgroundColor: 'transparent',
    },
  },
}));

export default function ResetPassword(props) {
  const classes = useStyles();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  // Password Checks
  const [length, setLength] = React.useState(false);
  const [uppercase, setUppercase] = React.useState(false);
  const [lowercase, setLowercase] = React.useState(false);
  const [number, setNumber] = React.useState(false);
  const [specialCharacter, setSpecialCharacter] = React.useState(false);
  const [match, setMatch] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [token, setToken] = React.useState('');

  const [newCustomer, setNewCustomer] = React.useState({
    password: '',
    confirmPassword: '',
  });

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShow((prev) => !prev);
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirm((prev) => !prev);
  };

  React.useEffect(() => {
    const query = new URLSearchParams(search);
    let token = query.get('token');
    setToken(token);
  }, []);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .test('checks', 'Checks password strength', async (value) => {
        // regex tests for each check
        let upper = /(?=.*[A-Z])/;
        let lower = /(?=.*[a-z])/;
        let number = /(?=.*[0-9])/;
        let special = /(?=.*[-?!@#$%^&*])/;
        let check =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-?!@#$%^&*])(?=.{8,})/;
        if (value && value.length >= 8) {
          setLength(true);
        } else {
          setLength(false);
        }
        if (value && upper.test(value)) {
          setUppercase(true);
        } else {
          setUppercase(false);
        }
        if (value && lower.test(value)) {
          setLowercase(true);
        } else {
          setLowercase(false);
        }
        if (value && number.test(value)) {
          setNumber(true);
        } else {
          setNumber(false);
        }
        if (value && special.test(value)) {
          setSpecialCharacter(true);
        } else {
          setSpecialCharacter(false);
        }
        if (value && check.test(value)) {
          return true;
        } else {
          return false;
        }
      }),
    confirmPassword: yup
      .string()
      .required('Please confirm password')
      .when('password', {
        is: (password) => (password && password.length > 0 ? true : false),
        then: yup
          .string()
          .test(
            'matches',
            'Passwords do not match',
            async (value, allValues) => {
              const password = allValues.parent.password;
              if (password === value) {
                setMatch(true);
                return true;
              } else {
                setMatch(false);
                return false;
              }
            }
          ),
      }),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      dispatch(resetCustomerPassword(values, token))
        .then((res) => {
          const email = res.data.email;
          dispatch(
            login(email, values.password, 'Your password has been updated')
          );
          setSubmitting(false);
          resetForm({});
        })
        .catch((err) => {
          setSubmitting(false);
          resetForm({});
          if (err.response.status === 403) {
            dispatch({
              type: ALERT_FAIL,
              payload: `${err.response.data.msg}, please try again`,
            });
          } else {
            dispatch({
              type: ALERT_FAIL,
              payload:
                'There was an error resetting your account. Please refresh and try again.',
            });
          }
        });
    },
  });

  if (auth.isAuthenticated) {
    return <Redirect to="/portal/dashboard" />;
  }

  return (
    <Paper className={classes.loginContainer}>
      <Grid container>
        <Grid item component="div">
          <Box mb={4}>
            <Typography className={classes.title} color="primary" variant="h4">
              Reset Password
            </Typography>
            <Typography className={classes.subtitle} variant="body1">
              Please enter new password.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Grid item xs={12}>
              <PortalTextField
                className={classes.input}
                fullWidth
                name="password"
                type={!show ? 'password' : 'text'}
                id="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                endAdornment={
                  <InputAdornment className={classes.adornment} position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {show ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <PortalTextField
                className={classes.input}
                fullWidth
                name="confirmPassword"
                type={!showConfirm ? 'password' : 'text'}
                id="confirmPassword"
                placeholder="Confirm Password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                endAdornment={
                  <InputAdornment className={classes.adornment} position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Box ml={2} mb={2}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {length ? (
                    <CheckCircleOutlineOutlinedIcon color="secondary" />
                  ) : (
                    <HighlightOffOutlinedIcon />
                  )}
                  <Typography className={classes.text}>
                    Password has 8 characters
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {uppercase ? (
                    <CheckCircleOutlineOutlinedIcon color="secondary" />
                  ) : (
                    <HighlightOffOutlinedIcon />
                  )}
                  <Typography className={classes.text}>
                    Password has one uppercase
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {lowercase ? (
                    <CheckCircleOutlineOutlinedIcon color="secondary" />
                  ) : (
                    <HighlightOffOutlinedIcon />
                  )}
                  <Typography className={classes.text}>
                    Password has one lowercase
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {number ? (
                    <CheckCircleOutlineOutlinedIcon color="secondary" />
                  ) : (
                    <HighlightOffOutlinedIcon />
                  )}
                  <Typography className={classes.text}>
                    Password has one Number
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {specialCharacter ? (
                    <CheckCircleOutlineOutlinedIcon color="secondary" />
                  ) : (
                    <HighlightOffOutlinedIcon />
                  )}
                  <Typography className={classes.text}>
                    Password has a special character - ?,!,@,#,$,%
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {match ? (
                    <CheckCircleOutlineOutlinedIcon color="secondary" />
                  ) : (
                    <HighlightOffOutlinedIcon />
                  )}
                  <Typography className={classes.text}>
                    Passwords match
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Box>
              <Button
                variant="contained"
                type="submit"
                simple="true"
                disabled={formik.isSubmitting}
              >
                Confirm Password
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
}
