import React from 'react';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../config';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    //maxWidth: 300,
    width: '100%',
    height: '100%',
    boxShadow: '0px 0px 10px 5px rgba(7,7,7,0.09)',
  },
  action: {
    '& a': {
      '&:hover, focus': {
        color: 'inherit',
      },
    },
  },
  media: {
    width: '100%',
    height: '200px',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    transition: 'all 0.5s ease',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: '500',
  },
  text: {
    fontSize: '1rem',
    fontWeight: '300',
    height: 75,
  },
  metatext: {
    fontSize: '0.8rem',
    fontWeight: '300',
    textAlign: 'right'
  },
  link: {
    textDecoration: 'none',
  },
}));

const BlogCard = (props) => {
  const classes = useStyles();
  const { author_first_name, author_last_name, date, description, header_img, id, title } = props.post;

  function dateFunction(date){
    // 2023-06-05
    let dateResult = "";
    const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    //Month
    if(Number(date[5]) <= 0){
      dateResult = monthArr[Number(date[6])-1]
    }
    else{
      dateResult = monthArr[Number(date.slice(5,7))-1]
    }
    //Day
    if(Number(date[8]) <= 0){
      dateResult = dateResult + " " + date[9] + ", ";
    }
    else{
      dateResult = dateResult + " " + date.slice(8,10) + ", ";
    }
    //Year
    dateResult = dateResult + date.slice(0,4);
    console.log(dateResult)
    return dateResult;
  }

  return (
    <Card className={classes.root}>
      <CardActionArea classes={classes.action}>
        <Link className={classes.link} to={`blog/${id}`}>
          <div className={classes.media}>
            <CardMedia className={classes.image} image={SERVER_URL + header_img} title={title} />
          </div>
          <CardContent>
            <Typography
              gutterBottom
              component="h2"
              color="primary"
              className={classes.title}
            >
              {title}
            </Typography>
            <Typography
              className={classes.text}
              color="textSecondary"
              component="p"
            >
              {description}
            </Typography>
            <Typography
              className={classes.metatext}
              color="textSecondary"
              component="p">
              {dateFunction(date)}, {author_first_name + " " + author_last_name}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
};

export default BlogCard;

BlogCard.propTypes = {
  post: PropTypes.object,
};
