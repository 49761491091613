import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Button,
  Box,
  Container,
  Hidden,
} from '@material-ui/core';

// components
import Page from 'components/Page/Page';
import HomeTitleSection from './Sections/HomeTitleSection';
import AboutAppSection from './Sections/AboutAppSection';
import CustomerReview from './Sections/CustomerReview';
import RecyclingRevSection from './Sections/RecyclingRevSection';
import RecycleCounter from './Sections/RecycleCounter';
import StepsToRecycleSection from '../HowItWorks/Section/StepsToRecycleSection';

// assets
import truckGif from 'assets/img/truckGifReduced.gif';
import phoneGif from 'assets/img/phoneGifReduced.gif';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  titleContainer: {
    marginBottom: theme.spacing(8),
  },
  title: {
    margin: '0',
  },
  image: {
    width: '100%',
    maxWidth: '700px',
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  largeText: {
    fontSize: '1.9rem',
    fontWeight: 300,
    lineHeight: '3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3125rem',
      lineHeight: 1.5,
    },
  },
  subContent: {
    fontSize: '1.5rem',
    fontWeight: '400',
    lineHeight: '3.2rem',
  },
  customerContainer: {
    width: '100vw',
    height: 'auto',
    padding: '2rem 0',
    backgroundColor: '#f7f7f8',
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  textBox: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '600px',
    },
  },
  textBlock: {
    textAlign: 'justify',
  },
  loginMobile: {
    marginLeft: theme.spacing(2),
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page>
      <div className={classes.main}>
        <div className={classes.titleContainer}>
          <HomeTitleSection />
          <Hidden smUp>
            <Grid container>
              <Box mt={5} ml={2}>
                <Button href="/signup">{t('buttons.signUp')}</Button>
                <Button className={classes.loginMobile} href="/login">
                  {t('buttons.logIn')}
                </Button>
              </Box>
            </Grid>
          </Hidden>
        </div>
        <Container className={classes.container}>
          <Typography color="primary" variant="h3" component="h2">
            <Trans>{t('home.simpleRecyclingTitle')}</Trans>
          </Typography>
          <Typography className={classes.largeText}>
            {t('home.simpleRecyclingBody')}
          </Typography>
        </Container>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              container
              justify="center"
              alignItems="center"
            >
              <img className={classes.image} src={phoneGif} alt="Money Gif" />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              container
              justify="flex-start"
              alignItems="center"
              className={classes.textBox}
            >
              <Grid item>
                <Typography color="primary" variant="h3">
                  {t('home.personalizedRecyclingTitle')}
                </Typography>
                <Typography className={classes.textBlock} variant="body2">
                  {t('home.personalizedRecyclingBody')}
                </Typography>
                <Box mt={5}>
                  <Button href="/signup">{t('buttons.signUp')}</Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container className={classes.container}>
          {/* <AboutAppSection /> */}
          <StepsToRecycleSection />
        </Container>
        <div className={classes.customerContainer}>
          <RecycleCounter />
        </div>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              container
              justify="center"
              alignItems="center"
            >
              <img className={classes.image} src={truckGif} alt="Truck Gif" />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              container
              justify="flex-start"
              alignItems="center"
              className={classes.textBox}
            >
              <Grid item>
                <Typography color="primary" variant="h3">
                  {t('home.recyclingForGoodTitle')}
                </Typography>
                <Typography className={classes.textBlock} variant="body2">
                  {t('home.recyclingForGoodBody')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xl" className={classes.container}>
          <RecyclingRevSection />
        </Container>
        <div className={classes.customerContainer}>
          <CustomerReview />
        </div>
        <Container maxWidth="lg" className={classes.container}>
          <Typography color="primary" variant="h3">
            {t('home.letsMakeChangeTogether')}
          </Typography>
          <Button href="/signup">{t('buttons.signUp')}</Button>
        </Container>
      </div>
    </Page>
  );
};

export default Home;

Home.propTypes = {
  children: PropTypes.node,
};
