// lib
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useZxing } from 'react-zxing';
import { useSelector } from 'react-redux';
import axios from 'axios';

// mui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Button } from '@material-ui/core';

// components
import BasicCard from 'components/Card/BasicCard';
import { SERVER_URL } from '../../../../config';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#007a9c',
    padding: '1rem',
    borderRadius: 20,
    boxShadow: theme.shadows[5],
    maxWidth: '900px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px 24px 24px',
    },
  },
  headerTitle: {
    fontWeight: 400,
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  iconAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    maxWidth: '700px',
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    fontWeight: 400,
    fontSize: '1rem',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover,&:focus': {
      color: theme.palette.primary.contrastText,
      background: 'inherit',
    },
  },
  thankyou: {
    color: theme.palette.tertiary.main,
  },
  logo: {
    width: '3.5rem',
    height: 'auto',
    padding: '1px',
    filter: 'drop-shadow(2px 2px 3px rgb(0 0 0 / 0.4))',
  },
  cameraView: {
    position: 'fixed',
    top: '0',
    left: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    boxShadow: 'none',
    overflow: 'none',
    width: '100%',
    padding: '0',
    margin: '0',
    height: '100%',
    minWidth: '100%',
    minHeight: '100%',
    justifyContent: 'center',
    zIndex: '1998',
    display: 'flex',
  },
  cameraViewButton: {
    width: '30%',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'fixed',
    bottom: '50px',
    zIndex: '999',
    display: 'flex',
  },
  message: {
    marginTop: theme.spacing(2),
  },
}));

export const BarcodeScanner = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const [result, setResult] = useState(''); // eslint-disable-line

  const { ref } = useZxing({
    onResult(result) {
      console.log('READ DATAMATRIX:', result.getText());
      setResult(result.getText());
      props.codeSetter(result.getText());
      props.callback();
    },
  });

  return (
    <>
      <div className={classes.cameraView}>
        <video ref={ref} />
        <Button
          variant="contained"
          onClick={props.callback}
          className={classes.cameraViewButton}
        >
          {t('dashboard.scanner.close')}
        </Button>
      </div>
    </>
  );
};

function BagScannerCard(props) {
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.access_token);
  const classes = useStyles(props);

  const [scannerOpen, setScannerOpen] = useState(0);
  const [datamatrixCode, setDatamatrixCode] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const codeScanned = datamatrixCode !== '';
    const noMessage = message === '';

    if (codeScanned && noMessage) {
      submitDatamatrixCode();
    }
  }, [datamatrixCode]);

  const submitDatamatrixCode = () => {
    const config = { headers: { 'Content-Type': 'application/json' } };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // submit the datamatrix-code to the backend

    const body = JSON.stringify({ code: datamatrixCode });

    // eslint-disable-next-line
    let ret = axios
      .post(`${SERVER_URL}/trackingcode/`, body, config)
      .then((response) => {
        setMessage(t('dashboard.scanner.successMsg'));
        setDatamatrixCode('');
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 409) {
          setMessage(t('dashboard.scanner.alreadyInDb'));
        } else {
          setMessage(t('dashboard.scanner.generalError'));
        }
        setDatamatrixCode('');
      });
  };

  return (
    <BasicCard>
      <Box p={2}>
        <Typography variant="h4" className={classes.headerTitle}>
          {t('dashboard.scanner.associateBag')}
        </Typography>
        <Typography className={classes.text}>
          {t('dashboard.scanner.clickButton')}
        </Typography>

        <Box p={2}>
          {scannerOpen === 1 && (
            <BarcodeScanner
              callback={() => setScannerOpen(0)}
              codeSetter={(code) => setDatamatrixCode(code)}
            />
          )}
          {scannerOpen === 1 && (
            <Button variant="contained" onClick={() => setScannerOpen(0)}>
              {t('dashboard.scanner.close')}
            </Button>
          )}
          {scannerOpen === 0 && datamatrixCode === '' && (
            <Button
              variant="contained"
              onClick={() => {
                setMessage('');
                setDatamatrixCode('');
                setScannerOpen(1);
              }}
            >
              {t('dashboard.scanner.scan')}
            </Button>
          )}
          {message !== '' && (
            <Box className={classes.message}>
              <Typography variant="h6">{message}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </BasicCard>
  );
}

export default BagScannerCard;

BagScannerCard.propTypes = {
  children: PropTypes.node,
};
