import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import { Typography } from '@material-ui/core';

// components
import BlogButton from 'components/BlogButton/BlogButton';

// blog lib
import posts from 'lib/blogPost';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '5rem',
    marginBottom: '5rem',
  },
  blogText: {
    paddingLeft: '4rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
    },
  },
  blogLink: {
    color: 'inherit',
    fontSize: '18px',
    textDecoration: 'none',
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
  blog: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'stretch',
  },
  bold: {
    fontWeight: 600,
  },
}));

const BlogSection = (props) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.blogText}>
        <Typography variant="h4" color="primary">
          Recycling Reimagined
        </Typography>
        <Link to="/blogs" className={classes.blogLink}>
          Eco<span className={classes.bold}>Blog</span>
        </Link>
      </div>
      <div className={classes.blog}>
        {posts.map((post) => (
          <BlogButton key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
};

export default BlogSection;
