import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';

// @material-ui/core components
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Button,
  Box,
  Hidden,
} from '@material-ui/core';

// assets
import bottleVideo from 'assets/img/bottlesFalling.mp4';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: '1 0 auto',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  card: {
    display: 'flex',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0,
  },
  header: {
    height: 'auto',
    minHeight: '300px',
    width: '100vw',
    display: 'flex',
    objectFit: 'contain',
    maxHeight: '3000px',
    [theme.breakpoints.down('sm')]: {
      objectFit: 'cover',
    },
  },
  headerTitle: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '10%',
    left: '5%',
  },
  title: {
    fontSize: '2rem',
    '@media (min-width:300px)': {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '5rem',
    },
    [theme.breakpoints.up('xl')]: {
      // fontSize: '8rem',
    },
  },
}));

function HomeTitleSection(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div style={{ position: 'relative' }}>
            <CardMedia>
              <video
                autoPlay
                loop
                muted
                playsInline
                // poster={canPoster}
                className={classes.header}
              >
                <source type="video/mp4" src={bottleVideo} />
              </video>
            </CardMedia>
            <Grid
              container
              component="div"
              direction="column"
              className={classes.headerTitle}
            >
              <Grid item>
                <Typography variant="h1" className={classes.title}>
                    <Trans>{t('home.makingChange')}</Trans>
                </Typography>
              </Grid>
              <Hidden xsDown>
                <Grid item>
                  <Box mt={5}>
                    <Button href="/signup">{t('buttons.signUp')}</Button>
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default HomeTitleSection;
