import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { Toolbar, Box, Paper } from '@material-ui/core';

// components
import Arrow from 'components/Arrow/Arrow';
import HowToStep from './HowToStep';
import HowToServiceType from './HowToServiceType';
import HowToSelectService from './HowToSelectService';

// assets
import ecoSupplies from 'assets/img/portal/howtoicons_ecosupplies.svg';
import containers from 'assets/img/portal/howtoicons_containers.svg';
import dollarSign from 'assets/img/portal/howtoicons_dollarSign.svg';
import sort from 'assets/img/portal/howtoicons_ecosuppliesandcontainers.svg';
import truck from 'assets/img/portal/howtoicons_truck.svg';
import rightArrow from 'assets/img/portal/rightarrow.svg';
import mobileRightArrow from 'assets/img/portal/mobilearrow_right.svg';
import mobileLeftArrow from 'assets/img/portal/mobilearrow_left.svg';
import leftArrow from 'assets/img/portal/leftarrow.svg';
import recyclingCenter from 'assets/img/evtek-recycling-location.png';
import schedule from 'assets/img/recycletek-calendar-icon.png';

// lib/data
import { content } from 'lib/content';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1000px',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },

  root: {
    maxWidth: '1280px',
    borderRadius: 20,
    padding: theme.spacing(6),
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  header: {
    width: '100%',
    marginBottom: theme.spacing(7),
  },
  row: {
    maxWidth: '950px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
    '&:last-child': {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(7),
      },
    },
  },
  section: {
    maxWidth: '950px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    '&:last-child': {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(7),
    },
  },
  sectionRight: {
    maxWidth: '950px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(7),
    },
  },
  image: {
    maxHeight: '80px',
    width: 'auto',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      maxHeight: '40px',
    },
  },
  title: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
      whiteSpace: 'normal',
    },
  },
  thin: {
    fontWeight: 300,
  },
  number: {
    fontSize: theme.typography.pxToRem(50),
    fontWeight: 400,
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(30),
    },
  },
  largeCard: {
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '500px',
    },
  },
  smallCard: {
    maxWidth: '500px',
  },
  cardText: {
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      hyphens: 'auto',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  hyperLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));

// TODO: clean up and refactor
const HowTo = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const USState = useSelector((state) => state.customer?.location.stateCode);
  const [service, setService] = useState('Walk In');

  const sortContainersText = {
    curbside: 'how.sortContainersText.curbside',
    walkIn: 'how.sortContainersText.walkIn',
    quickDropAndDriveThru: 'how.sortContainersText.quickDropAndDriveThru',
  };

  const setSelectedService = (type) => {
    setService(type);
  };

  const getText = () => {
    let text = '';
    switch (service) {
      case 'Curbside':
        text = t(sortContainersText.curbside);
        break;
      case 'Walk In':
        text = t(sortContainersText.walkIn);
        break;
      case 'Quick Drop':
        text = t(sortContainersText.quickDropAndDriveThru);
        break;
      case 'Drive Thru':
        text = t(sortContainersText.quickDropAndDriveThru);
        break;
      default:
        text = t(sortContainersText.curbside);
    }
    return text;
  };

  const CommonSteps = () => {
    return (
      <>
        <Box className={classes.section}>
          <HowToStep
            step="01"
            size="lrg"
            src={ecoSupplies}
            altText="EcoSupplies"
            header={
              <>
                {t('how.order')}{' '}
                <span className={classes.thin} color="primary">
                  {t('common.eco')}
                </span>
                {t('common.supplies')}
              </>
            }
            text={t(
              service === 'Walk In'
                ? 'how.orderSuppliesTextWalkIn'
                : 'how.orderSuppliesText',
            )}
          />
        </Box>
        <Arrow type="down-right" src={mobileRightArrow} />
        <Box className={classes.row}>
          <Arrow type="default" src={leftArrow} />
          <HowToStep
            step="02"
            src={containers}
            altText="Containers"
            header={t('how.collectItems')}
            text={
              <>
                {t('how.onlyStateRedemptionContainers', {
                  state: t(content[USState]?.state),
                })}{' '}
                <Link className={classes.hyperLink} to={'/portal/recyclable'}>
                  {t('how.whatCanYouRecycleLink')}
                </Link>{' '}
                {t('how.toSeeList')}
              </>
            }
          />
        </Box>
        <Box className={classes.row}>
          <HowToStep
            step="03"
            src={sort}
            altText="Sorted Items"
            header={t('how.sortItems')}
            text={getText()}
          />
          <Arrow type="default" src={rightArrow} />
        </Box>
      </>
    );
  };

  const CurbsideSteps = () => {
    return (
      <Box className={classes.sectionRight}>
        <HowToStep
          step="04"
          src={truck}
          altText="Truck"
          header={t('how.schedulePickupTitle')}
          text={t('how.schedulePickupBody')}
        />
      </Box>
    );
  };

  const FindLocationStep = () => {
    return (
      <>
        <Box className={classes.sectionRight}>
          <HowToStep
            step="04"
            size="lrg"
            src={recyclingCenter}
            altText="Find Location"
            header={t('how.findALocationTitle')}
            text={
              <>
                {t('how.useThe')}{' '}
                <Link className={classes.hyperLink} to={'/portal/location'}>
                  {t('how.recyclingLocationLink')}
                </Link>{' '}
                {t('how.toFindACenterNearYou')}
              </>
            }
          />
        </Box>
        <Arrow type="down-right" src={mobileRightArrow} />
      </>
    );
  };

  const DropInStep = () => {
    return (
      <Box className={classes.sectionRight}>
        <HowToStep
          step="05"
          src={sort}
          altText="Process"
          header={t('how.processContainersTitle')}
          text={t('how.processContainersBody')}
        />
      </Box>
    );
  };

  const QuickDropStep = () => {
    return (
      <Box className={classes.sectionRight}>
        <HowToStep
          step="05"
          src={sort}
          altText="Drop Off"
          header={t('how.dropOffTitle')}
          text={t('how.dropOffBody')}
        />
      </Box>
    );
  };

  const DriveThruSteps = () => {
    return (
      <>
        <Box className={classes.sectionRight}>
          <HowToStep
            step="05"
            src={schedule}
            altText="Schedule"
            header={t('how.scheduleATimeslotTitle')}
            text={t('how.scheduleATimeslotBody')}
          />
        </Box>
        <>
          <Arrow type="down-left" src={mobileLeftArrow} />
          <Box className={classes.row}>
            <HowToStep
              step="06"
              src={sort}
              altText="Drive Thru"
              header={t('how.driveThruTitle')}
              text={t('how.driveThruBody')}
            />
          </Box>
        </>
        <Arrow type="down-right" src={mobileRightArrow} />
      </>
    );
  };

  const GetPaidStep = () => {
    return (
      <Box className={classes.row}>
        <HowToStep
          step={service === 'Drive Thru' ? '07' : '06'}
          src={dollarSign}
          altText="Dollar Sign"
          header={t('how.getPaidTitle')}
          text={t('how.getPaidBody')}
        />
      </Box>
    );
  };

  return (
    <>
      <Toolbar />
      <Box className={classes.container}>
        <Paper className={classes.root}>
          <>
            <Box className={classes.header}>
              <HowToSelectService
                selectedService={service}
                setSelectedService={setSelectedService}
              />
              <HowToServiceType service={service} />
            </Box>
            <CommonSteps />
            {service === 'Curbside' && <CurbsideSteps />}
            {service !== 'Curbside' && <FindLocationStep />}
            {service === 'Walk In' && <DropInStep />}
            {service === 'Quick Drop' && <QuickDropStep />}
            {service === 'Drive Thru' && <DriveThruSteps />}
            {service !== 'Drive Thru' && (
              <Arrow type="down-left" src={mobileLeftArrow} />
            )}
            <GetPaidStep />
          </>
        </Paper>
      </Box>
    </>
  );
};

export default HowTo;
