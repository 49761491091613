import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/core components
import { Typography, Grid, Container, Toolbar } from '@material-ui/core';

import { SERVER_URL } from '../../config';

// components
import Page from 'components/Page/Page';
import { Parallax } from 'react-parallax';
import axios from "axios";
// blog lib


const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container_parallax: {
    width: '100vw',
    height: '60vh',
    marginBottom: '5rem',
    backgroundPosition: 'top',
  },
  titleBox: {
    width: '100%',
    height: '60vh',
  },
  title: {
    textAlign: 'center',
    fontSize: '2.5rem',
    fontWeight: '400',
    color: theme.palette.primary.contrastText,
  },
  subTitle: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1.5rem',
  },
  text: {
    fontSize: '1.15rem',
    fontWeight: '400',
    marginBottom: '1rem',
    textIndent: '5%',
  },
  textBox: {
    padding: '2rem',
    marginBottom: '5rem',
  },
  paragraphTitle: {
    fontSize: '1.15rem',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
}));

const Blog = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [post, setPost] = React.useState({ body: '' });
  const [postFormatedDate, setDate] = React.useState("");
  React.useEffect(() => {
    const config = { headers: { 'Content-Type': 'application/json' } }

    const dateFunction = (date) => {
      let dateResult = "";
      const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      if (Number(date[5]) <= 0) {
        dateResult = monthArr[Number(date[6]) - 1]
      } else {
        dateResult = monthArr[Number(date.slice(5, 7)) - 1]
      }
      //Day
      if (Number(date[8]) <= 0) {
        dateResult = dateResult + " " + date[9] + ", ";
      } else {
        dateResult = dateResult + " " + date.slice(8, 10) + ", ";
      }
      //Year
      dateResult = dateResult + date.slice(0, 4);
      setDate(dateResult);
    }

    const fetchPostInfo = async () => {
      const response = await axios.get(`${SERVER_URL}/blog/post/${id}`, config);
      setPost(response.data);
      dateFunction(response.data['date']);
    }

    fetchPostInfo();
  }, [id]);


  return (
    <Page>
      <div className={classes.main}>
        <Toolbar />
        <Parallax
          blur={2}
          strength={500}
          bgImage={SERVER_URL + post.header_img}
          className={classes.container_parallax}
          renderLayer={() => (
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0, .4 )',
              }}
            />
          )}
        >
          <Grid
            className={classes.titleBox}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Typography className={classes.title}>
                {post.title}
                <span className={classes.subTitle}>By {post.author_first_name + " " + post.author_last_name}</span>
                <span className={classes.subTitle}>{postFormatedDate}</span>
              </Typography>
            </Grid>
          </Grid>
        </Parallax>
        <Container maxWidth="md" className={classes.textBox}>
          <div dangerouslySetInnerHTML={{ __html: post.body.replaceAll("/static/blog_images", `${SERVER_URL}/static/blog_images`) }}></div>
        </Container>
      </div>
    </Page>
  );
};

export default Blog;
