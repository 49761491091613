import React from 'react';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

// Material UI components
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& a:focus, hover': {
      color: 'inherit',
    },
  },
  image: {
    position: 'relative',
    height: 250,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.4,
      },
      '& $imageTitle': {
        opacity: '100%',
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          border: '0',
        },
      },
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    textAlign: 'center',
    position: 'relative',
    fontWeight: 500,
    fontSize: '1.2rem',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${
      theme.spacing(1) + 6
    }px`,
    opacity: 0,
  },
}));

export default function BlogButton(props) {
  const classes = useStyles();
  const { id, title, img, width, position } = props.post;

  return (
    <div className={classes.root}>
      <ButtonBase
        href={`/blog/${id}`}
        focusRipple
        key={title}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={{
          width: width,
        }}
      >
        <span
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${img})`,
            backgroundPosition: `${position}`,
          }}
        />
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
          <Typography
            component="span"
            variant="caption"
            color="inherit"
            className={classes.imageTitle}
          >
            {title}
          </Typography>
        </span>
      </ButtonBase>
    </div>
  );
}

BlogButton.propTypes = {
  post: PropTypes.object,
};
