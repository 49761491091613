import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// React Slideshow Image
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Typography,
  Grid,
  Box,
  Hidden,
  Button,
} from '@material-ui/core';

// assets
import signupSkin from 'assets/img/phoneSkins/phone-signup.png';
import ecosuppliesSkin from 'assets/img/phoneSkins/phone-ecosupplies.png';
import pickupSkin from 'assets/img/phoneSkins/phone-pickup-scheduler.png';
import walletSkin from 'assets/img/phoneSkins/phone-ecowallet.png';

const slides = [
  {
    src: signupSkin,
    alt: 'Login Phone Screen',
  },
  {
    src: ecosuppliesSkin,
    alt: 'Eco Bin Phone Screen',
  },
  {
    src: pickupSkin,
    alt: 'Pickup Phone Screen',
  },
  {
    src: walletSkin,
    alt: 'Wallet Phone Screen',
  },
];

// Properties for React Slideshow Image
const properties = {
  infinite: true,
  pauseOnHover: true,
  defaultIndex: 0,
  canSwipe: true,
  duration: 3000,
  transitionDuration: 800,
  prevArrow: (
    <div>
      <i
        style={{ color: '#E6E7E9', cursor: 'pointer' }}
        className="fas fa-chevron-left"
      ></i>
    </div>
  ),
  nextArrow: (
    <div>
      <i
        style={{ color: '#E6E7E9', cursor: 'pointer' }}
        className="fas fa-chevron-right"
      ></i>
    </div>
  ),
};

const useStyles = makeStyles((theme) => ({
  stepsTitle: {
    fontWeight: 500,
    lineHeight: 1,
    fontSize: '3rem',
  },
  stepsSubtitle: {
    fontWeight: 500,
    lineHeight: 1,
    fontSize: '1.5rem',
  },
  stepsLeft: {
    padding: '0 1.5rem',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  stepsRight: {
    padding: '0 1.5rem',
    textAlign: 'left',
  },
  slideButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      cursor: 'auto',
    },
  },
  carouselContainer: {
    maxWidth: '300px',
  },
  carouselImage: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    objectFit: 'contain',
  },
  signUpButton: {
    padding: '0 1.5rem',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const StepsToRecycleSection = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const slideRef = React.useRef();
  const services = useSelector((state) => state.customer.location.services);
  const matches = useMediaQuery('(min-width:960px)');

  const changeSlide = (num) => {
    if (matches) {
      if (slideRef.current.state.index !== num) {
        slideRef.current.goTo(num);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item>
          <Box mb={12}>
            <Typography color="primary" variant="h3">
              {t('howItWorks.fourEasySteps')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <button
              className={classes.slideButton}
              onClick={() => changeSlide(0)}
              onMouseEnter={() => changeSlide(0)}
            >
              <Box className={classes.stepsLeft} mb={3}>
                <Typography color="primary" className={classes.stepsTitle}>
                  {t('howItWorks.stepOne.number')}
                </Typography>
                <Typography color="primary" className={classes.stepsSubtitle}>
                  {t('howItWorks.stepOne.title')}
                </Typography>
                <Typography variant="caption">
                  {t('howItWorks.stepOne.text')}
                </Typography>
              </Box>
            </button>
            <button
              className={classes.slideButton}
              onClick={() => changeSlide(1)}
              onMouseEnter={() => changeSlide(1)}
            >
              <Box className={classes.stepsLeft} mb={3}>
                <Typography color="primary" className={classes.stepsTitle}>
                  {t('howItWorks.stepTwo.number')}
                </Typography>
                <Typography color="primary" className={classes.stepsSubtitle}>
                  {t('howItWorks.stepTwo.title')}
                </Typography>
                <Typography variant="caption">
                  {t('howItWorks.stepTwo.text')}
                </Typography>
              </Box>
            </button>
          </Grid>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid item md={4} lg={3} container justify="center">
            <Grid item xs={12} className={classes.carouselContainer}>
              <Fade {...properties} autoplay ref={slideRef}>
                {slides.map((slide, index) => (
                  <div key={index}>
                    <img
                      src={slide.src}
                      alt={slide.alt}
                      className={classes.carouselImage}
                    />
                  </div>
                ))}
              </Fade>
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <button
              className={classes.slideButton}
              onClick={() => changeSlide(2)}
              onMouseEnter={() => changeSlide(2)}
            >
              <Box className={classes.stepsRight} mb={3}>
                <Typography color="primary" className={classes.stepsTitle}>
                  {t('howItWorks.stepThree.number')}
                </Typography>
                <Typography color="primary" className={classes.stepsSubtitle}>
                  {services.curbside
                    ? t('howItWorks.stepThree.title.pickup')
                    : t('howItWorks.stepThree.title.dropOff')}
                </Typography>
                <Typography variant="caption">
                  {t('howItWorks.stepThree.text')}
                </Typography>
              </Box>
            </button>
            <button
              className={classes.slideButton}
              onClick={() => changeSlide(3)}
              onMouseEnter={() => changeSlide(3)}
            >
              <Box className={classes.stepsRight} mb={3}>
                <Typography color="primary" className={classes.stepsTitle}>
                  {t('howItWorks.stepFour.number')}
                </Typography>
                <Typography color="primary" className={classes.stepsSubtitle}>
                  {t('howItWorks.stepFour.title')}
                </Typography>
                <Typography variant="caption">
                  {t('howItWorks.stepFour.text')}
                </Typography>
              </Box>
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={5}>
          <Button href="/signup">{t('buttons.signUp')}</Button>
        </Box>
    </React.Fragment>
  );
};

export default StepsToRecycleSection;
