import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

// A custom theme for this app
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7DA1CA',
      contrastText: '#ffffff',
    },
    primaryDark: {
      main: '#004f71',
      dark: '#003F5A',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#78D64B',
    },
    tertiary: {
      main: '#164E71',
    },
    grey: {
      main: '#E6E7E9',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#797777',
    },
    error: {
      main: '#ff3d00',
    },
    success: {
      main: '#78D64B',
    },
  },
  typography: {
    fontFamily: 'HelveticaNeue, Helvetica, Arial, sans-serif',
    h2: {
      fontSize: '3.8rem',
      fontWeight: 300,
      lineHeight: 1,
    },
    h3: {
      // fontFamily: 'Roboto',
      fontSize: '3rem',
      fontWeight: 300,
      lineHeight: 1,
      marginBottom: '1rem',
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
    h4: {
      fontSize: '2.5rem',
      fontWeight: 300,
    },
    h5: {
      fontSize: '2rem',
      fontWeight: 300,
    },
    h6: {
      fontSize: '1.5rem',
      fontWeight: 500,
      textTransform: 'inherit',
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontSize: '1.5rem',
      fontWeight: 300,
      lineHeight: 1.5,
      '@media (max-width:600px)': {
        fontSize: '1.125rem',
      },
    },
    caption: {
      fontSize: '1.125rem',
      fontWeight: 300,
      lineHeight: 1.5,
      display: 'block',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '12px',
        backgroundColor: '#78D64B',
        color: 'white',
        textAlign: 'center',
        textTransform: 'inherit',
        '&:hover': {
          backgroundColor: '#7DA1CA',
          color: 'white',
        },
      },
      text: {
        padding: '8px 36px',
        fontWeight: 500,
        fontSize: '18px',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
