// AUTH TYPES export const
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const LOGOUT = 'LOGOUT';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const LOAD_USER_FROM_STORAGE = 'LOAD_USER_FROM_STORAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_PREFERRED_LANGUAGE = 'SET_PREFERRED_LANGUAGE';

// VERIFICATION TYPES
export const CUSTOMER_SMS_SENT = 'CUSTOMER_SMS_SENT';
export const CUSTOMER_CODE_VERIFIED = 'CUSTOMER_CODE_VERIFIED';
export const CHECKING_CUSTOMER_CODE = 'CHECKING_CUSTOMER_CODE';
export const CUSTOMER_2FA_EMAIL_SENT = 'CUSTOMER_2FA_EMAIL_SENT';

// CUSTOMER types
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const CUSTOMER_PENDING = 'CUSTOMER_PENDING';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_CUSTOMER = 'REGISTER_CUSTOMER';
export const UNIQUE_CUSTOMER = 'UNIQUE_CUSTOMER';
export const NOT_UNIQUE_CUSTOMER = 'NOT_UNIQUE_CUSTOMER';
export const SERVICEABLE_ZIPCODE = 'SERVICEABLE_ZIPCODE';
export const NOT_SERVICEABLE_ZIPCODE = 'NOT_SERVICEABLE_ZIPCODE';
export const SET_CUSTOMER_STATECODE = 'SET_CUSTOMER_STATECODE';
export const SET_CUSTOMER_COORDINATES = 'SET_CUSTOMER_COORDINATES';
export const SET_CUSTOMER_SERVICES = 'SET_CUSTOMER_SERVICES';
export const SET_CUSTOMER_STATECOORDS = 'SET_CUSTOMER_STATECOORDS';
export const SET_SUPPORTED_LANGUAGES = 'SET_SUPPORTED_LANGUAGES';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';
export const SET_ACHIEVEMENTS = 'SET_ACHIEVEMENTS';
export const SET_ASSOCIATED_REDEMPTION_CENTER =
  'SET_ASSOCIATED_REDEMPTION_CENTER';

// PICKUPS TYPES
export const GET_PICK_UPS = 'GET_PICK_UPS';
// confirm pickup not used yet
export const CONFIRM_PICKUP = 'CONFIRM_PICKUP';
export const CREATE_PICK_UPS = 'CREATE_PICK_UPS';
export const CREATE_SKIP_REQUEST = 'CREATE_SKIP_REQUEST';
export const DELETE_SKIP_REQUEST = 'DELETE_SKIP_REQUEST';
export const PROCESSING_PICK_UP_REQUEST = 'PROCESSING_PICK_UP_REQUEST';
export const DELETE_PICK_UPS = 'DELETE_PICK_UPS';
export const UPDATE_PICK_UPS = 'UPDATE_PICK_UPS';
export const CREATE_RECURRING_PICKUPS = 'CREATE_RECURRING_PICKUPS';
export const SET_AVAILABLE_PICKUP_DAYS = 'SET_AVAILABLE_PICKUP_DAYS';
export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';
export const RESET_PICKUPS = 'RESET_PICKUPS';

// ALERT types
export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_FAIL = 'ALERT_FAIL';
export const ALERT_WARNING = 'ALERT_WARNING';
export const CLOSE_ALERT = 'CLOSE_ALERT';

// TRANSACTION types
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const PROCESSING_TRANSACTION = 'PROCESSING_TRANSACTION';
export const SET_TRANSACTION_INFO = 'SET_TRANSACTION_INFO';
export const SET_PAYMENTOPTIONS = 'SET_PAYMENTOPTIONS';
export const SET_TRANSFER_MESSAGE = 'SET_TRANSFER_MESSAGE';
export const SET_DWOLLA = 'SET_DWOLLA';
export const CONFIRM_DWOLLA = 'CONFIRM_DWOLLA';
export const RESET_DWOLLA = 'RSET_DWOLLA';
export const RESET_TRANSACTIONS = 'RESET_TRANSACTIONS';
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';

// SIDEBAR types
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';

// STORE types
export const SET_STORE = 'SET_STORE';
export const SET_CART = 'SET_CART';
export const DELETE_CART = 'DELETE_CART';
export const SET_SHIPPING_OPTIONS = 'SET_SHIPPING_OPTIONS';

// CAPTCHA types
export const SET_CAPTCHA = 'SET_CAPTCHA';
export const SET_VERIFYING = 'SET_VERIFYING';
export const SET_VERIFY = 'SET_VERIFY';
export const SET_PHONE = 'SET_PHONE';
export const SET_EMAIL = 'SET_EMAIL';
export const DELETE_VERIFICATION = 'DELETE_VERIFICATION';

// Metrics types
export const SET_METRICS = 'SET_METRICS';
export const RESET_METRICS = 'RESET_METRICS';

// Charities types
export const SET_CHARITIES_LIST = 'SET_CHARITIES_LIST';
