import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    fontSize: '1.15rem',
    fontWeight: '400',
    marginBottom: '1rem',
    textIndent: '5%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  text: {
    fontSize: '1.15rem',
    fontWeight: '400',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  listTitle: {
    fontSize: '1.15rem',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const BottleBillBlog = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.text}>
      <Typography gutterBottom className={classes.paragraph}>
        Most people consider themselves to be very “green”, and would most
        likely say they are highly conscious about the climate, including the
        effect that human beings have on it. According to a 2019 poll conducted
        by The Washington Post, about 8 in 10 Americans say that human activity
        is fueling climate change, and roughly half believe action is urgently
        needed within the next decade if humanity is to avert its worst effects.
        In the 21st century, it has become increasingly more difficult to ignore
        the growing concerns of this global crisis, and ignorance towards the
        wellbeing of our planet is generally frowned upon. The question is, how
        does the average American contribute to the process of saving our
        planet?
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        Something we do everyday is drink and eat, and our food often comes in
        single-use packaging. We use plastics, aluminum, paper, etc. You’ve
        probably gone out of your way to recycle a bottle or can once, and that
        is pretty cool. Maybe you didn’t know it, but you participated in the
        Bottle Bill! What’s the Bottle Bill? Way back in the ‘70s, Oregon
        introduced the Oregon Bottle Bill; a container deposit legislation that
        went into effect October 1972. They were the first of the 50 states to
        do so and not too long after, 9 other states followed suit. The
        container deposit legislation (or CDL for short) requires a refundable
        deposit on certain types of recyclable beverage containers in order to
        ensure an increased recycling rate.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        Of the 10 states that offer CDL, 8 give 5¢ back per container, while
        only 2 states give 10¢ back: Oregon & Michigan (and soon officially
        Connecticut). It’s been proven that because of the CDL, average
        recycling rates have gone up a great amount. The United States’ overall
        beverage container recycling rate is approximately 33%, while states
        with container deposit laws have a 70% average rate of beverage
        container recycling. Michigan's recycling rate of 97% from 1990 to 2008
        was the highest in the nation, as is its 10¢ deposit. The CDL also
        prevents littering drastically, with studies showing that total roadside
        litter was reduced by 30% to 64% in the states with bottle bills.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        We also see versions of the Bottle Bill all around the world, with more
        than10 countries in Europe implementing deposit return facilities and
        effectively increasing recycling rates and immensely reducing high
        levels of pollution. Some of these countries have the highest recycling
        rates in the world, and have had great results from implementing their
        own Bottle Bills. Norway for instance, has seen an outstanding 97%
        return rate on plastic bottles throughout their nation. Truly a
        marvelous accomplishment.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        In New York state, the Bottle Bill has been flourishing since 1982 - the
        year it was established. Here, it's called the Returnable Container Act
        (RCA). It has reduced roadside litter by 70% and in 2020 helped to
        recycle 5.5 billion plastic, glass and aluminum beverage containers
        totaling 241,505 tons; at no cost to local governments. There is no
        doubt the Bottle Bill is effective at keeping our planet clean, but how
        does the average New Yorker go about utilizing the Bottle Bill as
        efficiently as possible?
      </Typography>
      <Typography gutterBottom className={classes.text}>
        Here’s a breakdown of a few different ways you can participate:
      </Typography>
      <ul>
        <li>
          <Typography className={classes.listTitle}>
            Reversed Vending Machines
          </Typography>
          <Typography>
            A reverse vending machine is a device that instantly accepts used
            beverage containers and returns money to the user. Sometimes
            supermarkets will have these outside, and will incentivize users by
            using the money you received from the containers on your next
            shopping trip at their store. Keep in mind you might run into
            problems with store hours or long lines.
          </Typography>
        </li>
        <li>
          <Typography className={classes.listTitle}>
            Specialized Redemption Centers
          </Typography>
          <Typography>
            If your local supermarket or convenience center does not have
            reverse vending machines on the premises, or maybe Covid-19 has
            caused them to temporarily close and you're in the NYC/Long Island
            area, you should definitely check out the All Deposit Redemption
            Center in Brentwood, NY. A state-of-the-art facility, All Deposit
            can efficiently process any size load and have you in and out in
            minutes. All that’s needed is a way to bring the containers to the
            location, but if you don’t have a car… don’t fret! More info on
            scheduling a pick-up below.
          </Typography>
        </li>
        <li>
          <Typography className={classes.listTitle}>
            Donation Programs
          </Typography>
          <Typography>
            Did you know you can collect and redeem clean empties and donate the
            proceeds to worthy causes? Redemption centers will sometimes have
            collection envelopes on sight, or you can work directly with a
            non-profit organization like Angels of Long Island. They have a
            plethora of drop off locations on their website and host recycling
            events to raise money and help the local communities through tough
            economic times.
          </Typography>
        </li>
        <li>
          <Typography className={classes.listTitle}>
            Mobile Redemption
          </Typography>
          <Typography>
            It’s the 21st century, and since almost everything can be done from
            your phone - why not save the planet from the luxury of your couch.
            Recycletek.co is changing the way Americans view recycling. Through an
            organized and user-friendly pick-up and redemption system, Recycletek can
            work directly with you to help you find the closest redemption
            centers, coordinate pickups no matter the number of containers, and
            even work directly with non-profits and charities alike. Recycletek is
            leading the charge against climate change and creating a better
            future for the next generation by utilizing cutting edge technology
            and working on a local level with communities and individuals. You
            can sign up today{' '}
            <a className={classes.link} href="/signup">
              here
            </a>{' '}
            using your mobile device.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom className={classes.paragraph}>
        These different ways of recycling offer specific benefits, so the best
        course of action is to find which way works best for you. Maybe you use
        a few bottles and cans a week, and only need to make a couple trips a
        month to the reverse vending machines, maybe you’re a business that goes
        through hundreds of containers a week and need to schedule a
        pick-up/drop-off, maybe you’re looking to get more engaged with your
        community and help reverse the littering and soiling of the environment,
        or maybe you just want to make some extra money. No matter the need - we
        guarantee you there is an option here that suits your wishes.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
      Recycletek works with everyone from students and scouts, to businesses and
        charities. We want to demystify the Bottle Bill, and show everyone how
        it can work for them. There is really no better time than the present to
        give more attention to recycling and sustainability and how it affects
        our lives. Especially now, in the midst of the Covid-19 pandemic we see
        an increase in pollution all across the globe, and most of that is from
        a lack of transparency by governments and large corporations. Here at
        Recycletek, we’re focused on changing the way The United States views
        recycling. It’s no small task, but with enough people on board, we think
        we’re well on our way.
      </Typography>
      <Typography gutterBottom className={classes.text}>
        Want to learn more? Check out some of our other blog posts{' '}
        <a className={classes.link} href="/blogs">
          here
        </a>
        .
      </Typography>
    </div>
  );
};

export default BottleBillBlog;
