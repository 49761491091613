import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-verification-code-input';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  verificationLoggedIn,
  emailVerification,
} from 'redux/actions/verification';
import { updateCustomer } from 'redux/actions/customer';

// Form Validation
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import PortalInputLabel from 'components/TextField/PortalInputLabel';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';

// styles
const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: 0,
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: '5px',
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  button: {
    cursor: 'pointer',
    color: theme.palette.aqua.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  content: {
    [theme.breakpoints.up(400)]: {
      minWidth: 350,
    },
  },
  numberInput: {
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& input': {
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
      '&:focus': {
        borderColor: theme.palette.tertiary.main,
        caretColor: theme.palette.tertiary.main,
      },
    },
  },
}));

function EmailForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.auth.customer);
  const emailVerified = useSelector(
    (state) => state.verification.email_verified,
  );
  const [emailUsed, setEmailUsed] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [verficationCode, setVerificationCode] = useState('');

  useEffect(() => {
    emailVerified && handleEmailVerified();
  }, [emailVerified]);

  const handleReset = () => {
    formik.handleReset();
    props.handleClose('email');
    setEmailUsed('');
    setVerificationCode('');
    setShowVerification('');
    props.handleClose('email');
  };

  const { email } = props.validation;

  const validationSchema = yup.object({
    email,
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      phone_number: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      dispatch(verificationLoggedIn(values));
      actions.resetForm();
      setShowVerification(true);
      setEmailUsed(values.email);
    },
  });

  const handleSubmitCode = () => {
    dispatch(emailVerification(verficationCode));
  };

  const handleEmailVerified = () => {
    dispatch(updateCustomer({ email: emailUsed }));
    handleReset();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          style: { borderRadius: '10px' },
        }}
      >
        {!showVerification && (
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle id="form-dialog-title" color="primary">
              {t('account.form.changeEmail')}
            </DialogTitle>
            <DialogContent className={classes.content}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PortalInputLabel
                    classes={{ root: classes.label }}
                    id="email"
                  >
                    {t('account.form.email')}
                  </PortalInputLabel>
                  <PortalTextFieldNoShadow
                    fullWidth
                    type="email"
                    id="email"
                    name="email"
                    placeholder={customer.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleReset} className={classes.button}>
                {t('buttons.cancel')}
              </Button>
              <Button className={classes.button} type="submit">
                {t('buttons.submit')}
              </Button>
            </DialogActions>
          </form>
        )}
        {showVerification && (
          <>
            <DialogTitle id="form-dialog-title" color="primary">
              {t('account.form.phoneNumberCodeSent', { location: emailUsed })}
            </DialogTitle>
            <DialogContent className={classes.content}>
              <ReactCodeInput
                id="verification_code"
                name="email verification code"
                type="number"
                fields={4}
                autoFocus={false}
                className={classes.numberInput}
                onChange={(e) => setVerificationCode(e)}
              />
            </DialogContent>
            <DialogActions>
              <Button className={classes.button} onClick={handleReset}>
                {t('buttons.cancel')}
              </Button>
              <Button className={classes.button} onClick={handleSubmitCode}>
                {t('buttons.submit')}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}

export default EmailForm;
