// lib
import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  CircularProgress,
  Typography,
  IconButton,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// components
import { getCustomerBalance } from 'redux/actions/transactions';
import { ALERT_SUCCESS } from 'redux/actions/types';

// utils
import {
  formatMoney,
  formatTransactionHistory,
} from 'utils/formatTransactions';

const useStyles = makeStyles((theme) => ({
  info: {
    fontWeight: 300,
  },
  payout: {
    textTransform: 'capitalize',
  },
  deduction: {
    color: '#ba000d',
  },
  neutral: {
    color: theme.palette.text,
  },
  credit: {
    color: '#4caf50',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  transaction: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(6),
    },
  },
  main: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  description: {
    minWidth: '300px',
    maxWidth: '300px',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
      marginLeft: 'unset',
    },
  },
  expandButtonRoot: {
    height: '24px',
    borderRadius: '0px',
  },
  amount: {
    textAlign: 'right',
    minWidth: '50px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(12),
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
      marginRight: 'unset',
    },
  },
  balance: {
    marginTop: theme.spacing(1),
  },
  toggle: {
    marginRight: theme.spacing(1),
  },
  detailsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subAmount: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  copyToClipboard: {
    textDecoration: 'underline',
    cursor: 'pointer',
    maxWidth: 'max-content',
  },
}));

function TransactionListItem(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { transactionHistory } = useSelector((state) => state.transactions);
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState([]);

  React.useEffect(() => {
    const handleGetTransactions = async () => {
      setIsLoading(true);
      await dispatch(getCustomerBalance()).then((res) => {
        setIsLoading(false);
      });
    };
    handleGetTransactions();
  }, [dispatch]);

  const mymap = (description) => {
    switch (description) {
      case 'custom':
        return 'Custom';
      case 'withdrawal':
        return 'Withdrawal';
      case 'redemption_donation':
        return 'Redemption Donation';
      case 'redemption_deposit':
        return 'Redemption Deposit';
      case 'ecoshop_payment':
        return 'EcoShop Payment';
      case 'redemption_buy':
        return 'Redemption Buy';
      case 'donation_receive':
        return 'Received Donation';
      case 'account_funding':
        return 'Account Funding';
      case 'recycletek_fees_receive':
        return 'Received Recycletek Fees';
      case 'cash_payout':
        return 'Cash Payout';
      default:
        return description;
    }
  };

  React.useEffect(() => {
    let history = formatTransactionHistory(transactionHistory);
    setHistory([...history]);
  }, [transactionHistory]);

  const handleDetails = (index) => {
    const isExpanded = expanded.includes(index);
    const expandedList = expanded.filter((item) => item !== index);

    if (isExpanded) {
      setExpanded(expandedList);
    } else if (!isExpanded) {
      setExpanded((prevState) => [...prevState, index]);
    }
  };

  const handleCopyId = (id) => {
    navigator.clipboard.writeText(id);
    dispatch({
      type: ALERT_SUCCESS,
      payload: t('ecowallet.copied'),
    });
  };

  if (isLoading) {
    return (
      <Box
        pt={8}
        className={classes.spinner}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <CircularProgress color="secondary" />
        <Typography align="center" className={classes.spinnerText}>
          <Trans>{t('ecowallet.loadingTransactionHistory')}</Trans>
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      {history.map((transaction, index) => (
        <>
          <Box className={classes.transaction}>
            <Box className={classes.header}>
              <Typography variant="body2">{transaction.date}</Typography>
              <IconButton
                classes={{ root: classes.expandButtonRoot }}
                onClick={() => handleDetails(index)}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <Box className={classes.toggle}>
                  <Typography variant="body2">
                    {expanded.includes(index)
                      ? t('ecowallet.hideDetails')
                      : t('ecowallet.viewDetails')}
                  </Typography>
                </Box>
                {expanded.includes(index) ? (
                  <ExpandLess fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )}
              </IconButton>
            </Box>
            <Divider />
            <Box className={classes.main}>
              <Box className={classes.description}>
                <Typography>
                  <strong>{mymap(transaction.description)}</strong>
                </Typography>
                <Typography variant="body2">
                  {transaction.note && <span>{transaction.note}</span>}
                </Typography>
                <Typography variant="body2">
                  {t('ecowallet.ecowalletBalance')}{' '}
                  {formatMoney(transaction.balance)}
                </Typography>
                <Collapse in={expanded.includes(index)}>
                  {transactionHistory[index].passthrough_fees_microdollars !==
                    0 && (
                    <Box className={classes.detailsContainer}>
                      <Box className={classes.subAmount}>
                        <Typography variant="body2">
                          {t('ecowallet.transferFee')}
                        </Typography>
                        <Typography variant="body2">
                          {formatMoney(
                            transactionHistory[index]
                              .passthrough_fees_microdollars,
                          )}
                        </Typography>
                      </Box>
                      <Box className={classes.subAmount}>
                        <Typography variant="body2">
                          {t('ecowallet.netTransfer')}
                        </Typography>
                        <Typography variant="body2">
                          {formatMoney(
                            transaction.amount -
                              transactionHistory[index]
                                .passthrough_fees_microdollars,
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box className={classes.detailsContainer}>
                    <Typography variant="body2">
                      {t('ecowallet.transactionId')} {transaction.id}
                    </Typography>
                    <Typography
                      className={classes.copyToClipboard}
                      variant="body2"
                      onClick={() => handleCopyId(transaction.id)}
                    >
                      {t('ecowallet.copyId')}
                    </Typography>
                  </Box>
                </Collapse>
              </Box>
              <Box className={classes.amount}>
                <>
                  <Typography>
                    <div className={classes[transaction.color]}>
                      <strong>{formatMoney(transaction.amount)}</strong>
                    </div>
                  </Typography>
                </>
              </Box>
            </Box>
          </Box>
        </>
      ))}
    </Box>
  );
}

export default TransactionListItem;
