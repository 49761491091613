import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  text: {
    // fontSize: '1rem',
    maxWidth: '465px',
    textAlign: 'justify',
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
  },
}));
function HowToHeader(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Box mt={3}>
      <Box mb={2}>
        <Typography variant="h3" color="primary">
          {t('how.howItWorksTitle')}
        </Typography>
        <Typography className={classes.subtitle}>
          {t('how.howItWorksSubtitle')}
        </Typography>
      </Box>
    </Box>
  );
}

export default HowToHeader;
