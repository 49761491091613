import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// mui
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    color: theme.palette.primary.main,
  },
  subtext: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));

export default function HowToServiceType({ service }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Box className={classes.title}>
      <Typography variant="h4">
        {t(service)} {t('how.serviceTitle')}
      </Typography>
      {t(service) === 'Drive Thru' && (
        <Typography className={classes.subtext}>
          &nbsp;{t('how.appointmentRequired')}
        </Typography>
      )}
    </Box>
  );
}
