import React from 'react';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

/* eslint no-dupe-keys: "off" */

export const recycletekHQLocations = [
  {
    name: '46 Wicks Rd, Brentwood, New York',
    lat: 40.7773,
    lng: -73.26024,
  },
  {
    name: '440 N Wolfe Rd, Sunnyvale, California',
    lat: 37.38373,
    lng: -122.01301,
  },
];

// for data.hours sunday starts at 0, monday = 1, etc.
// input hours as local timezone
export const serviceLocations = [
  {
    lat: 40.7773,
    lng: -73.26024,
    info: {
      name: 'Brentwood',
      service: 'locations.center.brentwood.service',
      icon: <DirectionsWalkIcon />,
      address: '46 Wicks Road',
      city: 'Brentwood',
      state: 'New York',
      zipcode: '11717',
      phone: '631.807.3573',
      hours: [
        'locations.center.brentwood.hours.mon',
        'locations.center.brentwood.hours.tue',
        'locations.center.brentwood.hours.wed',
        'locations.center.brentwood.hours.thu',
        'locations.center.brentwood.hours.fri',
        'locations.center.brentwood.hours.sat',
        'locations.center.brentwood.hours.sun',
      ],
    },
    data: {
      available: true,
      stateCode: 'NY',
      timezone: 'America/New_York',
      hours: {
        0: null,
        1: ['09:00:00', '18:00:00'],
        2: ['09:00:00', '18:00:00'],
        3: ['09:00:00', '18:00:00'],
        4: ['09:00:00', '18:00:00'],
        5: ['09:00:00', '18:00:00'],
        6: null,
      },
    },
  },
  {
    lat: 37.31651,
    lng: -122.01526,
    info: {
      name: 'Creekside Park',
      service: 'locations.center.creeksidePark.service',
      icon: <DirectionsWalkIcon />,
      address: '10455 Miller Ave',
      city: 'Cupertino',
      state: 'California',
      zipcode: '95014',
      hours: [
        'locations.center.creeksidePark.hours.mon',
        'locations.center.creeksidePark.hours.tue',
        'locations.center.creeksidePark.hours.wed',
        'locations.center.creeksidePark.hours.thu',
        'locations.center.creeksidePark.hours.fri',
        'locations.center.creeksidePark.hours.sat',
        'locations.center.creeksidePark.hours.sun',
      ],
    },
    data: {
      available: true,
      stateCode: 'CA',
      timezone: 'America/Los_Angeles',
      hours: {
        0: null, // sun
        1: null, // mon
        2: null, // tue
        3: ['11:00:00', '19:00:00'], // wed
        4: null, // thu
        5: null, // fri
        6: null, // sat
      },
    },
  },
  {
    lat: 37.32476,
    lng: -122.04937,
    info: {
      name: 'Mary Avenue',
      service: 'locations.center.maryAve.service',
      icon: <DirectionsWalkIcon />,
      address: '10259 Mary Ave',
      city: 'Cupertino',
      state: 'California',
      zipcode: '95014',
      hours: [
        'locations.center.maryAve.hours.mon',
        'locations.center.maryAve.hours.tue',
        'locations.center.maryAve.hours.wed',
        'locations.center.maryAve.hours.thu',
        'locations.center.maryAve.hours.fri',
        'locations.center.maryAve.hours.sat',
        'locations.center.maryAve.hours.sun',
      ],
    },
    data: {
      available: true,
      stateCode: 'CA',
      timezone: 'America/Los_Angeles',
      hours: {
        0: null, // sun
        1: null, // mon
        2: null, // tue
        3: null, // wed
        4: null, // thu
        5: null, // fri
        6: ['10:00:00', '18:00:00'], // sat
      },
    },
  },
  {
    lat: 37.37616,
    lng: -122.01528,
    info: {
      name: 'Kifer Road',
      service: 'locations.center.kiferRd.service',
      icon: <DirectionsWalkIcon />,
      address: '725 Kifer Rd',
      city: 'Sunnyvale',
      state: 'California',
      zipcode: '94086',
      hours: [
        'locations.center.kiferRd.hours.mon',
        'locations.center.kiferRd.hours.tue',
        'locations.center.kiferRd.hours.wed',
        'locations.center.kiferRd.hours.thu',
        'locations.center.kiferRd.hours.fri',
        'locations.center.kiferRd.hours.sat',
        'locations.center.kiferRd.hours.sun',
      ],
    },
    data: {
      available: true,
      stateCode: 'CA',
      timezone: 'America/Los_Angeles',
      hours: {
        0: null, // sun
        1: null, // mon
        2: ['14:00:00', '19:00:00'], // tue
        3: null, // wed
        4: null, // thu
        5: null, // fri
        6: null, // sat
      },
    },
  },
  {
    lat: 37.58571,
    lng: -122.33126,
    info: {
      name: 'J & D Recycling',
      service: 'locations.center.jd.service',
      icon: <DirectionsWalkIcon />,
      address: '1015 N Amphlett Blvd',
      city: 'San Mateo',
      state: 'California',
      zipcode: '94401',
      hours: [
        'locations.center.jd.hours.mon',
        'locations.center.jd.hours.tue',
        'locations.center.jd.hours.wed',
        'locations.center.jd.hours.thu',
        'locations.center.jd.hours.fri',
        'locations.center.jd.hours.sat',
        'locations.center.jd.hours.sun',
      ],
    },
    data: {
      available: true,
      stateCode: 'CA',
      timezone: 'America/Los_Angeles',
      hours: {
        0: ['06:00:00', '12:00:00'], // sun
        1: ['06:00:00', '17:00:00'], // mon
        2: ['06:00:00', '17:00:00'], // tue
        3: ['06:00:00', '17:00:00'], // wed
        4: ['06:00:00', '17:00:00'], // thu
        5: ['06:00:00', '17:00:00'], // fri
        6: ['06:00:00', '17:00:00'], // sat
      },
    },
  },
];
