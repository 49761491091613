import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  ListItem,
  Divider,
  Button,
  Collapse,
  ListItemText,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PayPalTransfer from './PayPalTransfer';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  pickup: {
    color: theme.palette.primary.main,
  },
  title: {
    paddingRight: theme.spacing(6),
  },
  subtitle: {
    fontSize: '.75rem',
  },
  info: {
    fontWeight: 300,
  },
  editButton: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
  paypalButton: {
    backgroundColor: '#ffc439',
    padding: '5px 34px',
    color: '#0070ba',

    fontSize: theme.typography.pxToRem(14),
    '&:active': {
      backgroundColor: '#e6b033',
    },
    '&:hover': {
      color: '#0070ba',
      backgroundColor: '#e6b033',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
  },
  paypalImage: {
    height: '14px',
  },
  sublabel: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

function RedeemPaypal(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [paypalOpen, setPaypalOpen] = React.useState(false);

  // Opens and closes dropdown
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  // Opens and closes popup
  const handlePayPal = () => {
    setPaypalOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem
        classes={{ root: classes.listRoot }}
        button
        onClick={handleClick}
      >
        <ListItemText className={classes.pickup}>
          <Typography className={classes.title}>
            {t('ecowallet.paypal.payPalTransfer')}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box p={2}>
          <Box py={1} pl={3} mb={2} display="flex">
            <Button
              disableRipple
              variant="contained"
              classes={{ root: classes.paypalButton }}
              onClick={handlePayPal}
            >
              <img
                className={classes.paypalImage}
                src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png"
                border="0"
                alt="PayPal Logo"
              />
            </Button>
            {/* paypal form */}
            <PayPalTransfer
              open={paypalOpen}
              handleClose={handlePayPal}
              activeStep={0}
            />
          </Box>
          <Box pl={3}>
            <Typography className={classes.sublabel}>
              {t('ecowallet.minimumAmount')}
            </Typography>
            <Typography className={classes.sublabel}>
              {t('ecowallet.paypal.transferFee')}
            </Typography>
            <Typography className={classes.sublabel}>
              {t('ecowallet.maxTransferMonthly')}
            </Typography>
          </Box>
        </Box>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
}

export default RedeemPaypal;
