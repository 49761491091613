import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Box } from '@material-ui/core';

// assets
import evtekWorker from 'assets/img/recycletek-worker.png';
import recycleCenter from 'assets/img/recycletek-center.png';

const useStyles = makeStyles((theme) => ({
  businessRow: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  businessSubtitle: {
    fontSize: '1.5rem',
    fontWeight: '400',
    lineHeight: '1',
    marginBottom: '2.5rem',
  },
  businessImageBox: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(5),
  },
  businessImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
      display: 'block',
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  list: {
    fontSize: '1.125rem',
    padding: theme.spacing(0, 5),
    fontWeight: 300,
  },
  listItem: {
    lineHeight: '1.5',
    marginBottom: '1rem',
  },
  textBody: {
    fontSize: '1.125rem',
    textAlign: 'justify',
  },
}));

const BusinessCollectionSection = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item>
          <Typography color="primary" variant="h3">
            Business Collection
          </Typography>
          <Typography className={classes.businessSubtitle}>
            Recycletek Business Members get paid to recycle.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} className={classes.businessRow}>
          <div className={classes.businessImageBox}>
            <img
              src={evtekWorker}
              alt="Recycletek Worker"
              className={classes.businessImage}
            />
          </div>
          <Box mb={3}>
            <Typography className={classes.textBody} variant="caption">
              {t('howItWorks.reduceYourHaulingCosts')}
            </Typography>
          </Box>
          <Button href="/signup">{t('buttons.signUp')}</Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.businessImageBox}>
            <img
              src={recycleCenter}
              alt="Recycletek Recycle Center"
              className={classes.businessImage}
            />
          </div>

          <ul className={classes.list}>
            <li className={classes.listItem}>
              {t('howItWorks.businessCollectionInfo.pointOne')}
            </li>
            <li className={classes.listItem}>
              {t('howItWorks.businessCollectionInfo.pointTwo')}
            </li>
            <li className={classes.listItem}>
              {t('howItWorks.businessCollectionInfo.pointThree')}
            </li>
            <li className={classes.listItem}>
              {t('howItWorks.businessCollectionInfo.pointFour')}
            </li>
          </ul>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BusinessCollectionSection;
