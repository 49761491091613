import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { NavLink } from 'react-router-dom';

import { formatISO, getCurrentDate } from 'utils/dateFunctions.js';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkDwollaFunding } from 'redux/actions/transactions';
import { SET_DWOLLA } from 'redux/actions/types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Typography,
  Box,
  ListItem,
  Divider,
  Button,
  Collapse,
  ListItemText,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import DwollaTransfer from './DwollaTransfer';

// utils
import { checkDwollaTimestamp } from 'utils/dateFunctions';
import CheckbookTransfer from './CheckbookTransfer';

//icons
import checkIcon from 'assets/img/account_icons_check_white.svg';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  title: {
    paddingRight: theme.spacing(6),
  },
  subtitle: {
    fontSize: '.75rem',
  },
  info: {
    fontWeight: 300,
  },
  icon: {
    width: '45px',
    marginRight: '0.25rem',
  },
  button: {
    /*     background: theme.palette.primary.main, */
    padding: '5px 20px',
    fontSize: theme.typography.pxToRem(14),
  },
  sublabel: {
    fontSize: theme.typography.pxToRem(12),
  },
  errorAlert: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
  },
  errorContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '@media (min-width:600px)': {
      maxWidth: '600px',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
}));

function RedeemBankTransfer(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.auth.customer, shallowEqual);

  const [open, setOpen] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [checkbookOpen, setCheckbookOpen] = React.useState(false);

  // Opens and closes dropdown
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  // Opens and closes popup
  const handleCheckbook = () => {
    setCheckbookOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem
        classes={{ root: classes.listRoot }}
        button
        onClick={handleClick}
      >
        <ListItemText className={classes.pickup}>
          <Typography className={classes.title} color="primary">
            {t('ecowallet.checkOrACHBankTransfer')}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box p={2}>
          <Box py={1} pl={3} display="flex" flexDirection={'column'}>
            <Typography>{t('ecowallet.sendACheckToEmailInbox')}</Typography>
            <Box py={1} my={2}>
              <Button
                variant="contained"
                classes={{ root: classes.button }}
                onClick={handleCheckbook}
              >
                <img className={classes.icon} src={checkIcon} alt="Check" />
                {t('ecowallet.createCheck')}
              </Button>
              {/* checkbook.io form */}
              <CheckbookTransfer
                open={checkbookOpen}
                handleClose={handleCheckbook}
                activeStep={0}
              />
            </Box>
            <Box>
              <Typography className={classes.sublabel}>
                {t('ecowallet.checkFeeApplies')}
              </Typography>
              <Typography className={classes.sublabel}>
                {t('ecowallet.minimumCheckAmount')}
              </Typography>
              <Typography className={classes.sublabel}>
                {t('ecowallet.maxTransferMonthly')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
}

export default RedeemBankTransfer;
