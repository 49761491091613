import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// react-waypoint - detects when element is on screen
import { Waypoint } from 'react-waypoint';

// Luxon - date/time provider
import { DateTime } from 'luxon';

// React-odometer for counter animation
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';
import 'odometer/themes/odometer-theme-car.css';

// Material UI components
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 0',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  number: {
    marginLeft: theme.spacing(3),
    fontWeight: 400,
  },
}));

const RecycleCounter = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [odometerValue, setOdometerValue] = React.useState(0);
  const [duration, setDuration] = React.useState(500);
  const [amount, setAmount] = React.useState();

  const now = DateTime.local();
  const start = DateTime.fromISO('2021-03-01');

  const calculateTotal = () => {
    setDuration(500);
    setOdometerValue(amount);
  };

  const removeTotal = () => {
    setDuration(0);
    setOdometerValue(0);
  };

  React.useEffect(() => {
    const weeks = Math.floor(now.diff(start, ['weeks']).values.weeks);
    const amount = weeks * 21111 + 1560543;
    setAmount(amount);
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h5">{t('home.itemsRecycledSince')}</Typography>
      <Waypoint onEnter={calculateTotal} onLeave={removeTotal}>
        <Typography variant="h4" className={classes.number} color="primary">
          <Odometer duration={duration} value={odometerValue} theme="default" />
        </Typography>
      </Waypoint>
    </div>
  );
};

export default RecycleCounter;
