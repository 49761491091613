import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Box, List, Container, Typography } from '@material-ui/core';
// import Chip from '@material-ui/core/Chip';
import theme from 'themes/memberPortalTheme';
import MemberPortalCard from 'components/Card/MemberPortalCard';

// Components
import RedeemPaypal from './Sections/RedeemPaypal';
import TransactionListItem from './Sections/TransactionListItem';
import EcoCardTransfer from './Sections/EcoCardTransfer/EcoCardTransfer';
// import RedeemBankTransfer from './Sections/RedeemBankTransfer';
import RedeemCheckbookTransfer from './Sections/RedeemCheckbookTransfer';

const useStyles = makeStyles((theme) => ({
  main: {
    margin: 0,
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(5),
    },
  },
  title: {
    paddingRight: theme.spacing(6),
  },
}));

function EcoWallet(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const hasEcoCard = useSelector(
    (state) => state.auth.customer.onbe_account_number,
  );
  return (
    <Container maxWidth="md" className={classes.main}>
      <Box mb={3}>
        <MemberPortalCard
          open={true}
          background={`${theme.palette.primary.main}`}
          color={`${theme.palette.primary.contrastText}`}
          iconColor={`${theme.palette.secondary.main}`}
          title={t('ecowallet.redeemBalance')}
        >
          <Box>
            <Typography className={classes.title}>
              {t('ecowallet.choosePaymentType')}
            </Typography>
            <List>
              <RedeemPaypal />
            </List>
            {/* Dwolla
            <List>
              <RedeemBankTransfer />
            </List> */}
            <List>
              <RedeemCheckbookTransfer />
            </List>

            {hasEcoCard && (
              <List>
                <EcoCardTransfer />
              </List>
            )}
          </Box>
        </MemberPortalCard>
      </Box>
      <Box mb={3}>
        <MemberPortalCard
          open={true}
          background={`${theme.palette.primary.main}`}
          color={`${theme.palette.primary.contrastText}`}
          iconColor={`${theme.palette.secondary.main}`}
          title={t('ecowallet.transactionHistory')}
        >
          <TransactionListItem />
        </MemberPortalCard>
      </Box>
    </Container>
  );
}

export default EcoWallet;
