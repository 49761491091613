import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import { ALERT_SUCCESS } from 'redux/actions/types.js';

// MUI Components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Button } from '@material-ui/core';

// Api
import Customer from 'api/CustomerService';

// components
import PortalTextField from 'components/TextField/PortalTextField';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

// styles
const useStyles = makeStyles((theme) => ({
  input: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '250px',
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 300,
  },
  button: {
    '&:disabled': {
      backgroundColor: theme.palette.grey.dark,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default function ServiceAreaFrom(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    zipcode: yup
      .string()
      .required(t('signup.form.pleaseEnterZipcode'))
      .matches(/^[0-9]+$/, t('signup.form.mustBeOnlyDigits'))
      .min(5, t('signup.form.mustBeFiveDigits'))
      .max(5, t('signup.form.mustBeFiveDigits')),
  });

  const formik = useFormik({
    initialValues: {
      zipcode: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { zipcode } = values;
      Customer.isServiceable(zipcode)
        .then((res) => {
          dispatch({
            type: ALERT_SUCCESS,
            payload: t('signup.form.youAreLocatedInOurArea'),
          });
          props.handleChangeNewCustomer({ zipcode });
          props.handleServiceable(true);
        })
        .catch((error) => {
          props.handleNotify(true, zipcode);
          setSubmitting(false);
          resetForm({});
        });
    },
  });

  return (
    <div>
      <ScrollToTop />
      <Box mt={5}>
        <Box mb={3}>
          <Typography className={classes.title}>
            {t('signup.form.enterYourZipcode')}
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <PortalTextField
                className={classes.input}
                fullWidth
                id="zipcode"
                name="zipcode"
                placeholder={t('signup.form.zipcode')}
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                helperText={formik.touched.zipcode && formik.errors.zipcode}
              />
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {t('buttons.submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
}

ServiceAreaFrom.propTypes = {
  handleChange: PropTypes.func,
  handleServiceAreaCheck: PropTypes.func,
};
