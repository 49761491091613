import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    fontSize: '1.15rem',
    fontWeight: '400',
    marginBottom: '1rem',
    textIndent: '5%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  text: {
    fontSize: '1.15rem',
    fontWeight: '400',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  listTitle: {
    fontSize: '1.15rem',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const CleanerNewYorkBlog = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.text}>
      <Typography gutterBottom className={classes.paragraph}>
        The New York metropolitan region is populated by more than 20 million
        people. Approximately 70% of the world’s countries have fewer people
        than the NYC area, and only three U.S. states have more people. For such
        a dense place, one might expect it naturally would be congested and
        polluted.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        New York certainly produces a lot of waste, with much of it ending up
        piled on sidewalks for street pick-up, or just littered on the street.
        But it doesn’t have to be this way; just look at Tokyo, which is far
        denser and more populous, but much cleaner – and the trains run on-time,
        too!
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        By 2050, the UN estimates that 70% of the world’s population will live
        in urban areas, where the vast majority of energy is consumed and
        emitted, and where sustainable living ought to be made more paramount.
        Dense, dynamic, accessible cities – which make public transit viable and
        allow for walkability and biking – require basic infrastructure to work
        well. While urban farming, LEED-certified buildings, green roofs,
        bioswales, secure bike storage, and much more are certainly important,
        basic public health requires providing clean water, maintaining the
        sewers, and yes, taking out the trash (and recyleables)!
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        New York is one of the country’s dirtier cities. Most of the city wasn’t
        plotted out with alleys, so trash is piled up on sidewalks. Besides the
        Sanitation Department, private companies are contracted for commercial
        waste and recycleables, and the system is not without its flaws.
        Mountains of trash often incentivize people to pile their garbage on top
        of them further, feeding the millions of rats in the city – and
        underground, the MTA is challenged by rubbish removal, both in stations
        and on the tracks.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        Reducing the amount of waste is one solution – either by manufacturing
        products with the goal of upcycling in mind, or by not consuming as much
        in the first place. But improving how our used materials are dealt with
        in the region is not without precedent. After all, Battery Park City, a
        master planned neighorhood itself built on landfill, compacts much of
        its trash and keeps it off the sidewalks, and on Roosevelt Island, there
        is a pneumatic system which collects refuse within buildings. In
        addition, the city is hopefully working towards building an underground
        garbage storage network – as is common in many other global cities –
        which would allow waste and recyleable materials to be stored
        underground (rather than on the sidewalk) until DSNY arrives.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        But what to do with all the waste once it is picked up? Fresh Kills has
        been closed for decades now, so New York City’s Marine Transfer Stations
        transport containerized waste from the region to non-urban landfills
        beyond the region via barges. The NY & Atlantic Railway also transports
        waste from Long Island (connecting with other railroads in the regional
        network), keeping trucks off the road and reducing congestion. And
        Recycletek, of course, is working on increasing the quality and quantity of
        recycling through our industry-leading systems, services, and
        technology.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        Solving interdependent, intermodal, interdisciplinary problems in a
        region of more than 20 million people depends on planning and designing
        beyond boundaries – cultural, political, geographical, and technological
        – to question assumptions and identify contextual solutions.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        About the Author: Rayn Riel, AICP is a Recycletek volunteer contributor and
        is passionate about smart growth in the NYC area. He is a big believer
        in big cities and fascinated by the urban infrastructure that supports
        such levels of density and dynamism.
      </Typography>
    </div>
  );
};

export default CleanerNewYorkBlog;
