import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// assets
import metalPlasticGlassBottles from 'assets/img/metalPlasticGlassBottles.jpg'
import recyclingDropOff from 'assets/img/Recycling-Drop-off.jpg'
import canVideo from 'assets/img/canCompressed.mp4'
import truckVideo from 'assets/img/garbageTruckCompressed.mp4'
import canPoster from 'assets/img/canPoster.jpg'
import garbageTruckPoster from 'assets/img/garbageTruckPoster.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '500px',
    height: '500px',
    position: 'relative',
    marginLeft: '2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: '350px',
      marginLeft: '0',
      marginBottom: '2rem'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0
    },
    '& div:nth-child(1)': {
      top: '10%',
      [theme.breakpoints.down('sm')]: {
        top: 0
      }
    },
    '& div:nth-child(3)': {
      top: '60%',
      [theme.breakpoints.down('md')]: {
        top: '50%'
      }
    },
    '& div:nth-child(2)': {
      top: '-5%',
      left: '60%',
      [theme.breakpoints.down('md')]: {
        top: '-5%',
        left: '57%'
      },
      [theme.breakpoints.down('sm')]: {
        top: '0',
        left: '50%'
      }
    },
    '& div:nth-child(4)': {
      top: '45%',
      left: '60%',
      [theme.breakpoints.down('md')]: {
        left: '57%'
      },
      [theme.breakpoints.down('sm')]: {
        top: '50%',
        left: '50%'
      }
    }
  },
  box: {
    width: '250px',
    height: '150px',
    position: 'absolute',
    overflow: 'hidden',
    boxShadow: '20px 30px 0px 0px rgba(0,0,0,0.08)',
    [theme.breakpoints.down('md')]: {
      width: '225px',
      height: '125px'
    },
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      width: '50%',
      height: '50%'
    }
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}))

const ImageBlock = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <img
          className={classes.image}
          alt="Man with Bottles"
          src={metalPlasticGlassBottles}
        />
      </div>
      <div className={classes.box}>
        <video
          autoPlay
          loop
          muted
          playsInline
          poster={canPoster}
          className={classes.image}
        >
          <source type="video/mp4" src={canVideo} />
        </video>
      </div>
      <div className={classes.box}>
        <video
          autoPlay
          loop
          muted
          playsInline
          poster={garbageTruckPoster}
          className={classes.image}
        >
          <source type="video/mp4" src={truckVideo} />
        </video>
      </div>
      <div className={classes.box}>
        <img
          className={classes.image}
          alt="Man with Bottles"
          src={recyclingDropOff}
        />
      </div>
    </div>
  )
}

export default ImageBlock
