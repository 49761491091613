import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab, Typography, Grid } from '@material-ui/core';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SpeedIcon from '@material-ui/icons/Speed';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

// components
import ServiceInfo from 'components/ServiceInfo/ServiceInfo';
import RedemptionCenterLocations from './RedemptionCentersSection';

// assets
import theme from 'themes/theme';
import dropOffLocation from 'assets/img/phoneSkins/phone-dropoff-location.png';
import bulkPickUpSkin from 'assets/img/phoneSkins/phone-bulk-pickup.png';
import scheduleSkin from 'assets/img/phoneSkins/phone-scheduler.png';

const cardInfo = [
  {
    image: dropOffLocation,
    alt: 'Login Phone Screen',
    title: 'howItWorks.walkInAndCashOut',
    subtitle: 'howItWorks.startMakingChange',
    textBody: 'howItWorks.walkInExplanation',
    icon: <DirectionsWalkIcon />,
  },
  {
    image: scheduleSkin,
    alt: 'Schedule Phone Screen',
    title: 'howItWorks.scheduleAPickup',
    subtitle: 'howItWorks.donateYourItems',
    textBody: 'howItWorks.unlikeMunicipalPrograms',
    icon: <LocalShippingIcon />,
  },
  {
    image: bulkPickUpSkin,
    alt: 'Bulk Pickup Phone Screen',
    title: 'howItWorks.scheduleABulkPickup',
    subtitle: 'howItWorks.makeMoney',
    textBody: 'howItWorks.ourBulkServiceIsFor',
    icon: <LocalShippingIcon />,
  },
];

const otherServices = {
  quickDrop: {
    title: 'howItWorks.quickDrop',
    text: 'howItWorks.bringYourContainersIn',
    icon: <SpeedIcon />,
  },
  driveThru: {
    title: 'howItWorks.driveThru',
    text: 'howItWorks.similarToQuickDrop',
    icon: <DriveEtaIcon />,
  },
};

const CustomTabs = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRight: `3px solid ${theme.palette.grey.main}`,
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
    width: '3px',
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    fontSize: '1rem',
    color: theme.palette.primary.main,
    minHeight: 167,
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.palette.text.primary,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 120,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  columnOne: {
    padding: theme.spacing(1),
  },
  columnTwo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
  columnThree: {
    display: 'flex',
    alignItems: 'center',
  },
  serviceImg: {
    height: '500px',
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: '340px',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const DropoffSection = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const services = useSelector((state) => state.customer.location.services);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item className={classes.columnOne} sm={12} md={2}>
          <Typography color="primary" variant="h3">
            {t('howItWorks.services')}
          </Typography>
        </Grid>
        <Grid className={classes.columnTwo} item sm={12} md={5}>
          {services.curbside && (
            <CustomTabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="dropoff-options"
            >
              <CustomTab
                label={t('howItWorks.cashDropOff')}
                {...a11yProps(0)}
              />
              <CustomTab
                label={t('howItWorks.curbsidePickup')}
                {...a11yProps(1)}
              />
              <CustomTab label={t('howItWorks.bulkPickup')} {...a11yProps(2)} />
            </CustomTabs>
          )}
          {cardInfo.map(
            (card, index) =>
              value === index && (
                <img
                  className={classes.serviceImg}
                  src={card.image}
                  alt={card.alt}
                  key={index}
                />
              ),
          )}
        </Grid>
        <Grid className={classes.columnThree} item sm={12} md={5}>
          {cardInfo.map((card, index) => (
            <TabPanel key={index} value={value} index={index}>
              <ServiceInfo
                title={t(card.title)}
                subtitle={t(card.subtitle)}
                textBody={t(card.textBody)}
                icon={card.icon}
              />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
      <Grid className={classes.footer} container>
        <Grid className={classes.service} item sm={12} md={6}>
          <ServiceInfo
            title={t(otherServices.quickDrop.title)}
            textBody={t(otherServices.quickDrop.text)}
            icon={otherServices.quickDrop.icon}
          />
        </Grid>
        <Grid className={classes.service} item sm={12} md={6}>
          <ServiceInfo
            title={t(otherServices.driveThru.title)}
            textBody={t(otherServices.driveThru.text)}
            icon={otherServices.driveThru.icon}
          />
        </Grid>
      </Grid>
      <RedemptionCenterLocations />
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default DropoffSection;
