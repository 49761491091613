import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import { Typography, Box } from '@material-ui/core';
import recycleByStateAmountGraph from 'assets/img/blog/recycleByStateAmountGraph.png';
import recycleByStateGraph from 'assets/img/blog/recycleByStateGraph.png';
import beverageContainersGraph from 'assets/img/blog/beverageContainersGraph.png';
import containerSales from 'assets/img/blog/containerSales.png';

const useStyles = makeStyles((theme) => ({
  paragraphTitle: {
    fontSize: '1.15rem',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  paragraph: {
    fontSize: '1.15rem',
    fontWeight: '400',
    marginBottom: '1rem',
    textIndent: '5%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  text: {
    fontSize: '1.15rem',
    fontWeight: '400',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  listTitle: {
    fontSize: '1.15rem',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  image: {
    maxWidth: '100%',
    margin: theme.spacing(4, 0, 5),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0, 3),
    },
  },
}));

const ModernBillBlog = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.text}>
      <Typography gutterBottom className={classes.paragraph}>
        In our last{' '}
        <a className={classes.link} href="/blog/3">
          blog post
        </a>{' '}
        we discussed the origins of Bottle Bills, how they operate and what
        exactly they do. This week we dive into a little more depth and look at
        the Bottle Bills in other parts of the world. You’ll notice the
        differences in the bills between states. As mentioned last week 10
        states have Bottle Bills in effect. They are California, Connecticut,
        Hawaii, Iowa, Maine, Massachusetts, Michigan, New York, Oregon, and
        Vermont. Oregon implemented the first bill that is still in place in
        1971 and the other states followed suit over the next few decades. There
        are a few differences among the bills across the states but at their
        core, they are focused on increasing the rate of return of beverage
        containers and increasing the recycling rate in the state. These bills
        have been implemented in part to increase the recycling rates in the
        respective states and minimize waste which we can see from the following
        charts is immense in the United States. Not only is there a great amount
        of material produced and used but there is also a significant amount of
        waste.
      </Typography>
      <img src={containerSales} alt="Sales Graph" className={classes.image} />
      <img
        src={beverageContainersGraph}
        alt="Container Graph"
        className={classes.image}
      />
      <Typography className={classes.paragraphTitle}>
        Bottle Bills in the United States
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        One of the key differences among the legislature from state to state are
        what containers are accepted and what the value of the containers are.
        In Oregon for example, almost all beverage container types are accepted
        and universally for 10 cents each. This is surely a contributing factor
        to their high recycling rate. Easier access and greater incentives for
        recycling are great assets in reducing the amount of recyclable material
        that enters the waste stream. Michigan, who also has a 10 cent deposit
        also has a high rate of return. California has a system with multiple
        deposit rates where containers greater than 24 ounces are worth 10 cents
        and everything else is only 5 cents. Maine and Vermont also have higher
        values for liquor bottles. Beyond the per container value in each state,
        some states accept wine and liquor bottles and others do not. Below is a
        map that lists the per container value for each state and highlights the
        states that have active Bottle bills in the U.S.
      </Typography>
      <Typography className={classes.paragraphTitle}>
        Deposit Container Value by State
      </Typography>
      <img
        src={recycleByStateGraph}
        alt="State Graph"
        className={classes.image}
      />
      <Typography gutterBottom className={classes.paragraph}>
        All of the Bottle Bills have been successful so far but there is some
        variance between each state and that is affected by the deposit amount
        and the amount of containers that fall under the bill. We can see how
        the difference in legislature affects returns rates here:
      </Typography>
      <Typography className={classes.paragraphTitle}>
        Deposit Container Return Rates by State
      </Typography>
      <img
        src={recycleByStateAmountGraph}
        alt="State Amount Graph"
        className={classes.image}
      />
      <Typography gutterBottom className={classes.paragraph}>
        It should come as no surprise that the states with the highest deposit
        value also have the highest return rates. By offering a small financial
        incentive for returning cans and bottles, people who would not be
        interested in making the effort are now in the position to be engaged in
        a way that they previously would not have been. Additionally, we can
        look at the increased infrastructure in place where Bottle Bills are
        active and see how it makes it easier to return and recycle used
        containers. These two concepts seem small but on a large scale can be
        powerful forces to increase the rate of return for containers and
        increase recycling in general.
      </Typography>
      <Typography className={classes.paragraphTitle}>
        Bottle Bills around the Globe
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        Besides the United States and several other countries around the world
        have a bottle deposit system, the list includes Australia, Canada,
        Croatia, Germany, Finland, Iceland, Israel, Norway and Sweden. In
        addition to the countries that have very similar systems to the United
        States there are other systems:
      </Typography>
      <ul>
        <li>
          <Typography>
            The informal distributor regulated system in South Africa, where
            companies such as Coca Cola offer deposits on their containers. In
            total almost 40 countries worldwide have bottle bills in effect.
          </Typography>
        </li>
        <li>
          <Typography>
            In Norway, which introduced its system in 1972, the current return
            rate is 89% for cans and plastic bottles.
          </Typography>
        </li>
        <li>
          <Typography>
            Germany, which only introduced its bill in 2003 has a 98% rate for
            plastic bottles and 99% rate for cans, making it one of the most
            successful operations in the world.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom className={classes.paragraph}>
        There are more countries with less or more regulated deposit container
        systems but they all come down to the same basic scheme: return a bottle
        or can to a vendor or redemption center for the return on the deposit
        paid on the can. Whether run by the state or private industry these
        systems have the same basic goals.
      </Typography>
      <Typography className={classes.paragraphTitle}>
        Conclusions and Takeaways
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        As we can plainly see there are a wide variety of systems and schemes in
        place around the world. Each making an effort to reduce waste and
        increase the awareness and rate of recycling.
      </Typography>
      <ul>
        <li>
          <Typography>
            Bottle Bills are an effective way to reduce waste. They have been so
            overwhelmingly successful that the United Nations has called for
            increased resolutions and more container deposit systems.
          </Typography>
        </li>
        <li>
          <Typography>
            In the United States, a yearly estimated 11.6 million metric tons of
            CO2 is emitted in replacing 153 billion containers. This is in
            addition to the litter and waste polluting our natural environments
            that can be avoided.
          </Typography>
        </li>
        <li>
          <Typography>
            Using increased deposit amounts on top of existing Bottle Bills
            increases recycling rates.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom className={classes.paragraph}>
        Bottle Bills are a demonstrable good and hopefully we can continue to
        implement and support newer and more intricate systems to continue to
        increase recycling rates and the good that comes from them. They
        continue to increase recycling rates and minimize waste and greenhouse
        gas emissions around the world.
      </Typography>
      <Box mt={4}>
        <Typography color="primary" gutterBottom className={classes.source}>
          For Further Reading:
        </Typography>
        <Typography gutterBottom className={classes.text}>
          “Deposits on Plastic Bottles Backed by UN | News | The Times.”{' '}
          <a
            className={classes.link}
            href="https://www.thetimes.co.uk/article/deposits-on-plastic-bottles-backed-by-un-8cxdgv53s"
          >
            Link
          </a>
        </Typography>
        <Typography gutterBottom className={classes.text}>
          “State Beverage Container Deposit Laws.”{' '}
          <a
            className={classes.link}
            href="https://www.ncsl.org/research/environment-and-natural-resources/state-beverage-container-laws.aspx."
          >
            Link
          </a>
        </Typography>
        <Typography gutterBottom className={classes.text}>
          Nash-Hoff, Michele. “Why Are There So Few States with ‘Bottle Bill’
          Laws?” IndustryWeek{' '}
          <a
            className={classes.link}
            href="https://www.industryweek.com/the-economy/regulations/article/22008100/why-are-there-so-few-states-with-bottle-bill-laws"
          >
            Link
          </a>
        </Typography>
        <Typography gutterBottom className={classes.text}>
          Statista Infographics. “Infographic: Which Countries Have Bottle
          Deposit Systems?”{' '}
          <a
            className={classes.link}
            href="https://www.statista.com/chart/22963/global-status-of-plastic-bottle-recycling-systems/"
          >
            Link
          </a>
        </Typography>
        <Typography gutterBottom className={classes.text}>
          “Beverage Containers Wasted, 2010 and 2015 (Tons).”{' '}
          <a
            className={classes.link}
            href="https://www.container-recycling.org/index.php?option=com_content&view=article&id=606&Itemid=1153"
          >
            Link
          </a>
        </Typography>
      </Box>
    </div>
  );
};

export default ModernBillBlog;
