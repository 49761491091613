import {
  LOGIN_SUCCESS,
  ALERT_FAIL,
  ALERT_SUCCESS,
  USER_LOADING,
  SET_CUSTOMER,
  CUSTOMER_PENDING,
  DELETE_VERIFICATION,
  SET_CUSTOMER_COORDINATES,
  SET_SUPPORTED_LANGUAGES,
  SET_PREFERRED_LANGUAGE,
  SET_ACHIEVEMENTS,
} from './types.js';
import { getCustomerBalance, getPaymentData } from 'redux/actions/transactions';
import {
  getUserPickups,
  getAvailablePickupDays,
  getServiceType,
} from 'redux/actions/pickups';
import { getMetrics } from 'redux/actions/metrics';
import { getShop, loadCartFromStorage } from 'redux/actions/shop';
import axios from 'axios';
import { SERVER_URL } from '../../config.js';
import { configureConfigHeader } from 'utils/utils.js';
import { t } from 'i18next';

/* eslint-disable */

function returnDefaultConfig() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return config;
}

export const registerCustomer = (userInfo) => (dispatch) => {
  dispatch({ type: USER_LOADING });
  // convert the info to a JSON string
  const body = JSON.stringify(userInfo);
  // set up the config
  const config = returnDefaultConfig();
  axios
    .post(`${SERVER_URL}/customer/`, body, config)
    .then((res) => {
      dispatch({ type: DELETE_VERIFICATION });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: ALERT_SUCCESS,
        payload: t('actions.customer.accountCreatedSuccessfully'),
      });
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response.data.message);
      dispatch({
        type: ALERT_FAIL,
        payload: t('actions.customer.errorCreatingAccount'),
      });
    });
};

export const updateCustomer = (userInfo) => (dispatch, getState) => {
  // convert the info to a JSON string
  const body = JSON.stringify(userInfo);
  const config = configureConfigHeader(getState);
  axios
    .put(`${SERVER_URL}/customer/`, body, config)
    .then((res) => {
      dispatch({
        type: SET_CUSTOMER,
        payload: res.data,
      });
      dispatch({
        type: ALERT_SUCCESS,
        payload: t('actions.customer.accountUpdated'),
      });
    })
    .catch((err) => {
      dispatch({
        type: ALERT_FAIL,
        payload: t('actions.customer.errorUpdatingAccount'),
      });
    });
};

export const resetCustomerPassword =
  (userInfo, token) => (dispatch, getState) => {
    // convert the info to a JSON string
    const body = JSON.stringify(userInfo);
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return axios.put(`${SERVER_URL}/customer/`, body, config);
  };

export const updatePayPal = (userInfo) => (dispatch, getState) => {
  // convert the info to a JSON string
  const body = JSON.stringify(userInfo);
  const config = configureConfigHeader(getState);
  axios
    .put(`${SERVER_URL}/customer/`, body, config)
    .then((res) => {
      dispatch({
        type: SET_CUSTOMER,
        payload: res.data,
      });
      dispatch({
        type: ALERT_SUCCESS,
        payload: t('actions.customer.payPalLinked'),
      });
    })
    .catch((err) => {
      dispatch({
        type: ALERT_FAIL,
        payload: t('actions.customer.errorConnectingPayPal'),
      });
    });
};

export const getMultiple = (promises) => (dispatch) => {
  return Promise.all([...promises]);
};

export const getCustomerInfo = () => (dispatch, getState) => {
  dispatch(
    getMultiple([
      dispatch(getMetrics()),
      dispatch(getServiceType()),
      dispatch(getCustomerBalance()),
      dispatch(getPaymentData()),
      dispatch(getShop()),
      dispatch(loadCartFromStorage()),
      // dispatch(checkDwollaFunding()),
    ]),
  )
    .then(() => {
      dispatch({ type: CUSTOMER_PENDING, payload: false });
    })
    .catch((err) => {
      console.log(err);
      // if (
      //   err.response.data &&
      //   err.response.data.msg === 'Zipcode is not serviceable'
      // ) {
      //   const bulk = 'na';
      //   const curbside = 'na';
      //   dispatch({
      //     type: SET_SERVICE_TYPE,
      //     payload: { bulk, curbside },
      //   });
      // }
      dispatch({ type: CUSTOMER_PENDING, payload: false });
    });
};

// figure out how to make HO redux action creator
export const checkCustomerCode = (verificationMethod) => (dispatch) => {
  dispatch(verificationMethod());
};

// popup with helper text to help direct user
export const helperText = (message) => (dispatch) => {
  dispatch({ type: ALERT_SUCCESS, payload: message });
};

export const getCustomerCoords = (address, errCallback) => (dispatch) => {
  const params = `${address.house}+${address.street},+${address.city},+${address.state}`;

  axios
    .get(`https://nominatim.openstreetmap.org/search?q=${params}}&format=json`)
    .then((res) => {
      const lat = res.data[0]?.lat;
      const lon = res.data[0]?.lon;

      if (!lat || !lon) {
        errCallback();
      } else if (lat && lon) {
        dispatch({
          type: SET_CUSTOMER_COORDINATES,
          payload: { lat, lon },
        });
      }
    })
    .catch((err) => {
      errCallback();
    });
};

export const getSupportedLanguages = () => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  axios
    .get(`${SERVER_URL}/customer/supported_languages`, config)
    .then((res) => {
      dispatch({
        type: SET_SUPPORTED_LANGUAGES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ALERT_FAIL,
        payload: 'sorry, there was an error getting our supported languages',
      });
    });
};

export const setPreferredLanguage = (language) => (dispatch, getState) => {
  const data = { preferred_language: language };
  const config = configureConfigHeader(getState);
  const body = JSON.stringify(data);
  axios
    .put(`${SERVER_URL}/customer/`, body, config)
    .then((res) => {
      dispatch({
        type: ALERT_SUCCESS,
        payload: t('account.form.preferredLanguageUpdated'),
      });
      dispatch({
        type: SET_PREFERRED_LANGUAGE,
        payload: language,
      });
    })
    .catch((err) => {
      dispatch({
        type: ALERT_FAIL,
        payload: t('account.form.errorSettingLanguage'),
      });
    });
};

export const getAchievements = () => (dispatch, getState) => {
  const config = configureConfigHeader(getState);

  axios
    .get(`${SERVER_URL}/customer/achievements`, config)
    .then((response) => {
      dispatch({
        type: SET_ACHIEVEMENTS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ALERT_FAIL,
        payload: t('metrics.achievementsDataErrMsg'),
      });
    });
};
