import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box } from '@material-ui/core';

// assets
import pickupSkin from 'assets/img/phoneSkins/phone-pickup-scheduler.png';
import walletSkin from 'assets/img/phoneSkins/phone-ecowallet.png';
import metricsSkin from 'assets/img/phoneSkins/phone-metrics.png';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0',
    paddingBottom: theme.spacing(4),
  },
  caption: {
    fontSize: '1.2rem',
    margin: '0',
    lineHeight: '1.8rem',
    fontWeight: '400',
  },
  list: {
    fontSize: '1.125rem',
    lineHeight: '1.4rem',
    margin: '0',
  },
  listItem: {
    lineHeight: 1.5,
  },
  phoneImage: {
    width: '100%',
    maxWidth: '250px',
    height: 'auto',
    display: 'block',
    margin: '0 auto',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

const AboutAppSection = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid container direction="row">
      <Grid item>
        <Typography color="primary" variant="h3" className={classes.title}>
          <Trans>{t('home.recycletekAppTitle')}</Trans>
        </Typography>
      </Grid>
      <Grid container direction="row" justify="space-between" item spacing={5}>
        <Grid item sm={12} md={4}>
          <div className={classes.card}>
            <img
              className={classes.phoneImage}
              src={pickupSkin}
              alt="Pickup Phone Skin"
            />
            <div className={classes.textBlock}>
              <Typography color="primary" variant="h6">
                {t('home.scheduleAPickup')}
              </Typography>
              <Box mt={1}>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    {t('home.reservePickupDay')}
                  </li>
                  <li className={classes.listItem}>
                    {t('home.setSingleAndRecurringPickups')}
                  </li>
                  <li className={classes.listItem}>
                    {t('home.getAutomaticReminders')}
                  </li>
                  <li className={classes.listItem}>
                    {t('home.linkAccountToGoogleAndAlexa')}
                  </li>
                </ul>
              </Box>
            </div>
          </div>
        </Grid>
        <Grid item sm={12} md={4}>
          <div className={classes.card}>
            <img
              className={classes.phoneImage}
              src={walletSkin}
              alt="Wallet Phone Skin"
            />
            <div className={classes.textBlock}>
              <Typography color="primary" variant="h6">
                {t('home.getPaid')}
              </Typography>
              <Box mt={1}>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    {t('home.chooseHowToGetPaid')}
                  </li>
                  <li className={classes.listItem}>
                    {t('home.depositIntoEcoWallet')}
                  </li>
                  <li className={classes.listItem}>
                    {t('home.linkPayPalOrBankAccount')}
                  </li>
                  <li className={classes.listItem}>
                    {t('home.donateToCausesYouBelieveIn')}
                  </li>
                </ul>
              </Box>
            </div>
          </div>
        </Grid>
        <Grid item sm={12} md={4}>
          <div className={classes.card}>
            <img
              className={classes.phoneImage}
              src={metricsSkin}
              alt="Metric Phone Skin"
            />
            <div className={classes.textBlock}>
              <Typography color="primary" variant="h6">
                {t('home.trackYourImpact')}
              </Typography>
              <Box mt={1}>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    {t('home.seeYourEnvironmentalImpact')}
                  </li>
                  <li className={classes.listItem}>
                    {t('home.viewMetricsOverTime')}
                  </li>
                  <li className={classes.listItem}>
                    {t('home.verifyMetrics')}
                  </li>
                  <li className={classes.listItem}>
                    {t('home.shareAchievementsCreateCommunity')}
                  </li>
                </ul>
              </Box>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutAppSection;
