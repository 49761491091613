import {
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGOUT,
  LOAD_USER_FROM_STORAGE,
  ALERT_FAIL,
  ALERT_SUCCESS,
  RESET_TRANSACTIONS,
  RESET_PICKUPS,
  RESET_METRICS,
  SET_LANGUAGE,
  RESET_CUSTOMER,
} from './types';
import { SERVER_URL } from 'config.js';
import { configureConfigHeader } from 'utils/utils.js';
import axios from 'axios';

export const login = (email, password, message) => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING,
  });

  const config = configureConfigHeader(getState);
  const body = JSON.stringify({ email, password });
  axios
    .post(`${SERVER_URL}/auth/`, body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: ALERT_SUCCESS,
        payload: message,
      });
    })
    .catch((err) => {
      dispatch({
        type: ALERT_FAIL,
        payload: err.response.data?.message,
      });
    });
};

export const resetPassword = (email) => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  const body = JSON.stringify(email);

  return axios.post(`${SERVER_URL}/auth/resetpassword`, body, config);
};

export const loadUserFromStorage = () => (dispatch) => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser.user.access_token) {
      dispatch({
        type: LOAD_USER_FROM_STORAGE,
        payload: storedUser,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// checks if user has promo code to proceed to sign up
export const checkPromo = (code) => (dispatch, getState) => {
  const body = JSON.stringify({ promotion_code: code });
  const config = configureConfigHeader(getState);
  return axios.post(`${SERVER_URL}/auth/check_promotion_code`, body, config);
};

export const getLocationCode = async (lat, lon) => {
  try {
    const res = await axios.get(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`,
    );
    return res.data.principalSubdivisionCode;
  } catch (err) {
    console.log(err);
  }
};

export const setLanguage = (language) => (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: language,
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: RESET_METRICS,
  });
  dispatch({
    type: RESET_TRANSACTIONS,
  });
  dispatch({
    type: RESET_PICKUPS,
  });
  dispatch({
    type: RESET_CUSTOMER,
  });
  dispatch({
    type: LOGOUT,
  });
};
