import auth from './auth';
import pickups from './pickups';
import customer from './customer';
import alert from './alert';
import sidebar from './sidebar';
import metrics from './metrics';
import charities from './charities';
import { store, cart } from './shop';
import transactions from './transactions';
import verification from './verification';
import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
  alert,
  auth,
  cart,
  customer,
  pickups,
  metrics,
  sidebar,
  store,
  transactions,
  verification,
  charities,
});
