import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    fontSize: '1.15rem',
    fontWeight: '400',
    marginBottom: '1rem',
    textIndent: '5%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  text: {
    fontSize: '1.15rem',
    fontWeight: '400',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  source: {
    fontSize: '1.25rem',
    fontWeight: '600',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  listTitle: {
    fontSize: '1.15rem',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const GreatBottleBillBlog = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.text}>
      <Typography gutterBottom className={classes.paragraph}>
        In many states it seems like common knowledge that you can return your
        bottles and cans to the local beer store, deposit center or even grocery
        store for 5 cents to 10 cents - depending on what state you live in.
        There are currently 10 states that have container deposit legislation.
        Being that Recycletek plays a key role in the deposit container recycling
        process, we take pride in exemplifying a strong understanding of the
        history of recycling, specifically the history of Bottle Bill Programs
        a.k.a Container Return Systems.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        Due to the manufacturing and transportation costs of glass bottles,
        there has always been a private effort to reclaim and reuse these
        containers. However before there was the financial impact of deposit
        laws, few customers were willing to make the effort. Previously In the
        early and mid-twentieth century, the makers of soda or and bottled beer
        would request that customers bring the bottles back and offered a small
        deposit in return, but due to the small number of bottles in
        circulation, this was not an altogether effective practice.Once
        manufacturing bottles became cheaper, distributors no longer wanted to
        be hassled with accepting their empty containers back. Regardless of the
        negative environmental impact, bottles were designed to be disposable.
        With this mindset, many who bought bottles or cans felt free to throw
        them away without a care in the world. The overflowing landfills and
        littered highways gained the attention of activists and local elected
        officials during the late 60’s and throughout the 70’s. Government
        officials needed to discover a way to minimize this waste, effectively
        putting some responsibility back on the manufacturers, distributors and
        sellers of the bottles. In the late 60’s there was a real effort to
        protect the environment but it was still a fledgling movement for
        society. There were virtually no easily available systems in place for
        people and businesses to recycle. Bottle Bills were one of the first
        major pieces of legislation to bring a standardized way of recycling to
        the public.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        The first Bottle Bill was passed in Vermont in 1953 but was overturned
        only 4 years later. The first bill that still stands today was passed in
        Oregon in 1971 and took effect in 1972, the purpose of the law was to
        incentivize customers to return their containers, effectively reducing
        the total amount of waste while increasing the amount of containers that
        were reclaimed and then truly recycled. The bill itself legislates that
        a consumer pays a deposit to the retailer when buying a deposit marked
        beverage container, then that customer is able to refunded their deposit
        containers at a redemption center, beverage center, retail store or even
        a reverse vending machine. Fun Fact: technically any location that sells
        deposit marked containers must by law accept the deposit containers back
        and pay the deposit fee back to their customers. While this may sound
        complicated it is a simple process in practice. Once the customer is
        finished with the drink they simply collect their deposit containers and
        take them to their local redemption location of choice, in return they
        get the few cents they paid refunded. Over the next few decades several
        states followed Oregon’s lead and now the list of states includes
        California, Connecticut, Hawaii, Iowa, Maine, Massachusetts, Michigan,
        New York, Vermont and even Guam.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        Originally the bills were not all that effective as the industry had to
        scramble to install proper logistics and infrastructure to support
        associated businesses and participants of the system. For many players
        in the industry the Bottle Bill was initially viewed as a burden. Over
        time people and businesses grew accustomed to the opportunity of
        bringing back and accepting the return of bottles (and later cans). In
        fact the Bottle Bill has proven to be a massive success and arguably one
        of the most effective recycling programs to exist. The recycling and
        return rates in the states that enacted Bottle Bills grew substantially
        and there was a massive reduction in beverage container littering. In
        fact today we see that states with a Bottle Bill program have over
        double the recycling rate for deposit containers compared to states
        without the Bottle Bill system. Additionally, states with the Bottle
        Bill have proportionally less pollutants than states without the Bottle
        Bill.
      </Typography>
      <Typography gutterBottom className={classes.paragraph}>
        Overall, the way that we look at recycling containers is largely through
        the lens of Bottle Bills and deposits. Initially implemented to reduce
        the amount of waste and litter, the Bottle Bill has become an example of
        just how effective a monetary based recycling program can be. All
        parties can be meaningfully engaged and contribute to building a better
        cleaner world. Manufacturers are now held accountable for the production
        of the single use materials they produce. Through the Bottle Bill
        consumers are rewarded for being stewards of the earth - true
        environmental advocates. With a monetary incentive in place for properly
        recycling containers we are one step closer to living in the circular
        economy that planet earth deserves.
      </Typography>
      <Box mt={4}>
        <Typography color="primary" gutterBottom className={classes.source}>
          Sources:
        </Typography>
        <Typography gutterBottom className={classes.text}>
          “Beverage Companies Embrace Recycling, Until It Costs Them - The New
          York Times.”{' '}
          <a
            className={classes.link}
            href="https://www.nytimes.com/2019/07/04/business/plastic-recycling-bottle-bills.html"
          >
            Link
          </a>
        </Typography>
        <Typography gutterBottom className={classes.text}>
          Clayton Coleman. “Bottle Bills and Curbside Collection: An Overview of
          Recycling Policy Approaches.” Environmental and Energy Study Institute{' '}
          <a
            className={classes.link}
            href="https://www.eesi.org/articles/view/bottle-bills-and-curbside-collection-an-overview-of-recycling-policy-approa"
          >
            Link
          </a>
        </Typography>
        <Typography gutterBottom className={classes.text}>
          Jørgensen, Finn Arne. “A Pocket History of Bottle Recycling.” The
          Atlantic, February 27, 2013{' '}
          <a
            className={classes.link}
            href="https://www.theatlantic.com/technology/archive/2013/02/a-pocket-history-of-bottle-recycling/273575/"
          >
            Link
          </a>
        </Typography>
        <Typography gutterBottom className={classes.text}>
          User, Super. “What Is a Bottle Bill?”{' '}
          <a
            className={classes.link}
            href="https://www.bottlebill.org/index.php/about-bottle-bills/what-is-a-bottle-bill"
          >
            Link
          </a>
        </Typography>
      </Box>
    </div>
  );
};

export default GreatBottleBillBlog;
