import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// mui
import { Box, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  option: {
    color: theme.palette.primary.main,
    border: 'none',
    textTransform: 'unset',
    opacity: '0.65',
  },
  selected: {
    opacity: '1',
  },
}));

export default function HowToSelectService({
  setSelectedService,
  selectedService,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const services = useSelector((state) => state.customer.location.services);

  const serviceList = [
    /*{
      label: 'how.services.curbside',
      available: services.curbside,
      selected: selectedService === 'Curbside',
    },*/
    {
      label: 'how.services.walkIn',
      available: services.dropOff,
      selected: selectedService === 'Walk In',
    },
    {
      label: 'how.services.quickDrop',
      available: services.quickDrop,
      selected: selectedService === 'Quick Drop',
    },
    {
      label: 'how.services.driveThru',
      available: services.driveThrough,
      selected: selectedService === 'Drive Thru',
    },
  ];

  return (
    <Box className={classes.container}>
      {serviceList.map(
        (item) =>
          item.available && (
            <Button
              variant="outlined"
              className={`${classes.option} ${
                item.selected && classes.selected
              }`}
              onClick={() => setSelectedService(t(item.label))}
            >
              <Typography>{t(item.label)}</Typography>
            </Button>
          ),
      )}
    </Box>
  );
}
