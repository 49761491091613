import React from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/core components
import { Typography, Grid, Box } from '@material-ui/core'

// assets
import rewards from 'assets/img/recycletek-rewards-bin.png';

const useStyles = makeStyles((theme) => ({
  rewardsImage: {
    width: '100%',
    maxWidth: '500px',
    height: 'auto'
  },
  textBlock: {
    textAlign: 'justify'
  }
}))

const RewardsSection = (props) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography color="primary" variant="h3">
            {t('howItWorks.rewards')}
          </Typography>
          <Box>
            <Typography className={classes.textBlock} variant="caption">
              {t('howItWorks.rewardsExplanation')}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justify="center"
          alignItems="center"
        >
          <img
            src={rewards}
            alt="Rewards Images"
            className={classes.rewardsImage}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RewardsSection
