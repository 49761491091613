import React from 'react'
import { useTranslation, Trans } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'

// Components
import EcoTitle from 'components/Typography/EcoTitle'

// Assets
import ecoShopKrate from 'assets/img/ecosupplies-bin.png';
import ecoShopBin from 'assets/img/ecosupplies-collapse-bin.png';
import blueBag from 'assets/img/ecosupplies-bag.png';
import qrCode from 'assets/img/recycletek-qrcode.png';

const useStyles = makeStyles((theme) => ({
  item1: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2
    }
  },
  item2: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  },
  container: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    maxWidth: '500px',
    maxHeight: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
      maxHeight: '400px'
    }
  },
  dummy: {
    marginTop: '100%'
  },
  imageBox: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& div:nth-child(3)': {
      backgroundImage: `url(${ecoShopKrate})`,
      backgroundSize: '95%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },
    '& div:nth-child(2)': {
      backgroundImage: `url(${qrCode})`,
      backgroundSize: '75%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },
    '& div:nth-child(4)': {
      backgroundImage: `url(${ecoShopBin})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },
    '& div:nth-child(1)': {
      backgroundImage: `url(${blueBag})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }
  },
  image: {
    width: '50%',
    height: '50%'
  },
  textBlock: {
    textAlign: 'justify'
  }
}))

const EcoSupplySection = (props) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation();

  return (
    <Grid container spacing={3} justify="center">
      <Grid
        className={classes.item1}
        item
        sm={12}
        md={6}
        lg={6}
        container
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <EcoTitle
            regular="Recycletek Eco"
            bold="Supplies"
            variant="h3"
            color="primary"
          />
          <Typography className={classes.textBlock} variant="caption">
            <Trans>{t('howItWorks.ecoSuppliesExplanation')}</Trans>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        className={classes.item2}
        item
        sm={12}
        md={6}
        lg={6}
        container
        justify="center"
        alignItems="center"
      >
        <div className={classes.container}>
          <div className={classes.dummy}></div>
          <div className={classes.imageBox}>
            <div className={classes.image}></div>
            <div className={classes.image}></div>
            <div className={classes.image}></div>
            <div className={classes.image}></div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default EcoSupplySection
