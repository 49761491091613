// Importing modules
import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import axios from "axios";
import {useSelector} from 'react-redux';
// Assets
import freshKills from 'assets/img/blog/freshKills.jpg';
import crushedcans from 'assets/img/blog/crushedcans.png';
import modernBill from 'assets/img/blog/modernbill.png';
import evtekCenterSortingBlueCrop from 'assets/img/recycletek-sorting-worker.png';

import BottleBillBlog from '../views/Blog/Section/BottleBillBlog';
import GreatBottleBillBlog from '../views/Blog/Section/GreatBottleBillBlog';
import CleanerNewYorkBlog from '../views/Blog/Section/CleanerNewYorkBlog';
import ModernBillBlog from '../views/Blog/Section/ModernBillBlog';

//Newest First!
const posts = [
  {
    id: '4',
    title: 'The Modern Bottle Bill Around the World',
    alt: 'Bottles',
    author: 'Ben Honeycutt',
    date: 'Nov 5, 2021',
    img: `${modernBill}`,
    width: '100%',
    position: 'center',
    synopsis: 'A look at the Bottle Bills in other parts of the world.',
    body: <ModernBillBlog />,
  },
  {
    id: '3',
    title: 'The Great Bottle Bill',
    alt: 'Cans',
    author: 'Ben Honeycutt',
    date: 'Oct 5, 2021',
    img: `${crushedcans}`,
    width: '100%',
    position: '84% -1px',
    synopsis: 'The history of the deposit container bill.',
    body: <GreatBottleBillBlog />,
  },
  {
    id: '2',
    title: 'How to Return Bottles and Cans',
    alt: 'Bottles',
    author: 'Jude Walter',
    date: 'May 24, 2021',
    img: `${evtekCenterSortingBlueCrop}`,
    width: '100%',
    position: 'top',
    synopsis: 'How to the Bottle Bill can help you recycle better.',
    body: <BottleBillBlog />,
  },
  {
    id: '1',
    title: 'A Cleaner New York',
    alt: 'Fresh Kills',
    author: 'Rayn Riel',
    date: 'Feb 2, 2021',
    img: `${freshKills}`,
    width: '100%',
    position: 'center',
    synopsis:
      'How New York has solved its waste problem in the past and its options for a cleaner future.',
    body: <CleanerNewYorkBlog />,
  },
];

export default posts;
